import { Roles } from "../components/roles";

export async function getAccountsFromApi() {
  const response = await fetch("/api/accounts", {
    method: "GET",
  });
  const json = await response.json();
  return json.result;
}

type AddAccountParameters = {
  first_name: string;
  last_name: string;
  password?: string
  role: Roles;
  email: string;
};

export async function addAccount({ first_name, last_name, password, role, email }: AddAccountParameters) {
  const response = await fetch("/api/accounts", {
    method: "POST",
    body: JSON.stringify({
      first_name, last_name, password, role, email
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

type EditAccountParameter = {
  id: string;
  first_name: string;
  last_name: string;
  password?: string
  role: Roles;
  email: string;
};

export async function editAccount({ id, first_name, last_name, password, role, email }: EditAccountParameter) {
  const response = await fetch(`/api/accounts/${id}`, {
    method: "PATCH",
    body: JSON.stringify({
      first_name, last_name, password, role, email
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

type DeleteAccountParameter = {
  id: string;
};

export async function deleteAccountInApi({ id }: DeleteAccountParameter) {
  const response = await fetch(`/api/accounts/${id}`, {
    method: "DELETE",
  });
  const json = await response.json();
  return json;
}

type StaffLoginParameters = {
  email: string;
  password: string;
};

export async function staffLogin({ email, password }: StaffLoginParameters) {
  const response = await fetch("/api/login", {
    method: "POST",
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok){
    throw new Error("Couldn't Login")
  }
  return response.json();
}

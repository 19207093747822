import { useEffect, useState, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowUp, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StaffModal from "../../components/StaffModal";
import { deleteAccountInApi, getAccountsFromApi, addAccount, editAccount } from "../../api/accounts";
import validator from "validator";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Account } from "../../types";
import TableSearch from "../../components/TableSearch";
import TableFilter from "../../components/TableFilter";
import { Roles } from "../../components/roles";

function Accounts(props: any) {
  let mounted = useRef(true);
  const { t } = useTranslation();
  const [editElement, setEditElement] = useState<Account | undefined>(undefined);
  const [deleteElement, setDeleteElement] = useState<number>(-1);
  const [isAdding, setAdding] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [accountFirstNameSearch, setAccountFirstNameSearch] = useState<string>("");
  const [accountLastNameSearch, setAccountLastNameSearch] = useState<string>("");
  const [accountEmailSearch, setAccountEmailSearch] = useState<string>("");
  const [accountRoleSearch, setAccountRoleSearch] = useState<string[]>(Object.values(Roles));

  //Default is by first name desc
  const [sortBy, setSortBy] = useState<string>("first_name");
  const [isAsc, setIsAsc] = useState<boolean>(false);

  function setSort(by: string) {
    if (sortBy !== by) {
      setSortBy(by);
      setIsAsc(false);
    } else {
      setIsAsc(!isAsc);
    }
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<Account>({
    mode: "onBlur",
    reValidateMode: 'onChange'
  });

  async function getAccounts() {
    let accounts = await getAccountsFromApi();
    if (mounted) {
      setAccounts(accounts);
    }
  }

  useEffect(() => {
    getAccounts();
    return () => {
      mounted.current = false;
    }
  }, []);

  return (
    <div className="m-1 h-100">
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>
              <div className="header">
                <span onClick={() => { setSort("first_name") }}>
                  {t("accounts.FirstName")}
                  {sortBy === "first_name" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={accountFirstNameSearch} onChange={(data) => { setAccountFirstNameSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("last_name") }}>
                  {t("accounts.LastName")}
                  {sortBy === "last_name" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={accountLastNameSearch} onChange={(data) => { setAccountLastNameSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("email") }}>
                  {t("accounts.Email")}
                  {sortBy === "email" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={accountEmailSearch} onChange={(data) => { setAccountEmailSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">

                <span onClick={() => { setSort("role") }}>
                  {t("accounts.Role")}
                  {sortBy === "role" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableFilter
                  options={
                    Object.values(Roles).map((role) => { return { label: t(`accounts.${role}`), value: role } })}
                  state={accountRoleSearch}
                  onChange={(data) => { setAccountRoleSearch(data) }}
                />
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {accounts.sort((a, b) => {
            return (b[sortBy as keyof Account]?.localeCompare(a[sortBy as keyof Account] || "") || 0) * (isAsc ? 1 : -1)
          })
            .filter((account) => account.first_name.toLocaleLowerCase().includes(accountFirstNameSearch.toLocaleLowerCase()))
            .filter((account) => account.last_name.toLocaleLowerCase().includes(accountLastNameSearch.toLocaleLowerCase()))
            .filter((account) => account.email.toLocaleLowerCase().includes(accountEmailSearch.toLocaleLowerCase()))
            .filter((account) => accountRoleSearch.includes(account.role))
            .map((x) => (
              <tr key={x.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditElement(x);
                  setValue('id', x.id);
                  setValue('first_name', x.first_name);
                  setValue('last_name', x.last_name);
                  setValue('email', x.email);
                  setValue('role', x.role);
                  setValue('password', "");
                }}>
                <td>{x.first_name}</td>
                <td>{x.last_name}</td>
                <td>{x.email}</td>
                <td>{t(`accounts.${x.role}`)}</td>
                <td>
                  <div className="d-flex">

                    {(props.loginID === x.id) ? (<div></div>
                    ) : (
                      <div
                        className="mx-2"
                        onClick={(event) => {
                          event.stopPropagation();
                          setDeleteElement(accounts.indexOf(x));
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="text-danger clickableIcon"
                          size="1x"
                        />
                      </div>
                    )
                    }

                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        className="d-flex px-2 py-1 justify-content-end bg-white border-top"
        style={{ position: "sticky", right: "5px", bottom: "0", zIndex: 3 }}
      >
        <Button
          onClick={() => {
            setAdding(true);
            setEditElement({ first_name: "", last_name: "", email: "", role: Roles.boatManager } as Account);
            setValue('id', undefined as any);
            setValue('first_name', "");
            setValue('last_name', "");
            setValue('email', "");
            setValue('role', Roles.boatManager);
            setValue('password', "");
          }}
          variant="secondary"
        >
          <FontAwesomeIcon icon={faPlus} className="text-white me-2" />
          {t("accounts.AddAccount")}
        </Button>
      </div>
      <StaffModal
        header={isAdding ? t("accounts.AddAccount") : t("accounts.EditAccount")}
        show={!!editElement}
        successText={isAdding ? t("common.Add") : t("common.Edit")}
        disableNext={!isValid}
        loadingNext={loading}
        onHide={() => {
          setEditElement(undefined);
          setAdding(false);
        }}
        onSuccess={() => {
          setLoading(true);
          handleSubmit((data) => {
            if (isAdding) {
              addAccount(data).then(async () => {
                await getAccounts();
                setEditElement(undefined);
                setAdding(false);
                setLoading(false);
              });
            } else {
              editAccount(data).then(async () => {
                await getAccounts();
                setEditElement(undefined);
                setAdding(false);
                setLoading(false);
              });
            }
          })()
        }}
      >
        <Form>
          <Controller
            name="first_name"
            control={control}
            defaultValue={editElement?.first_name}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accounts.FirstName")}</Form.Label>
                <Form.Control type="text" {...field} isInvalid={!!errors.first_name} />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("accounts.Name"),
                }),
              },
            }}
          />
          <Controller
            name="last_name"
            control={control}
            defaultValue={editElement?.last_name}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accounts.LastName")}</Form.Label>
                <Form.Control type="text" {...field} isInvalid={!!errors.last_name} />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("accounts.LastName"),
                }),
              },
            }}
          />
          <Controller
            name="email"
            control={control}
            defaultValue={editElement?.email}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accounts.Email")}</Form.Label>
                <Form.Control type="text" {...field} isInvalid={!!errors.email} />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("accounts.Email"),
                }),
              },
              validate: (value: string) =>
                validator.isEmail(value) ||
                t("common.messages.required", {
                  val: t("accounts.Email"),
                }).toString(),
            }}
          />
          <Controller
            name="password"
            control={control}
            defaultValue={editElement?.password}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accounts.Password")}</Form.Label>
                <Form.Control type="password" {...field} isInvalid={!!errors.password} />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              validate: (value: any) => {
                if (!value) {
                  return true
                }
                if (!validator.isStrongPassword(value)) {
                  return t("accounts.messages.strongPW").toString()
                }
                return true
              }

            }}
          />
          <Controller
            name="role"
            control={control}
            defaultValue={editElement?.role}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accounts.Role")}</Form.Label>
                <Form.Select {...field}>
                  {Object.values(Roles).map((role) => { return (<option value={role}>{t(`accounts.${role}`)}</option>) })}
                </Form.Select>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("accounts.Role"),
                }),
              },
            }}

          />

        </Form>

      </StaffModal>
      <StaffModal
        header={t("accounts.DeleteAccount")}
        hideColor="secondary"
        successText={t("common.Delete")}
        successColor="danger"
        show={deleteElement !== -1}
        loadingNext={loading}
        onHide={() => {
          setDeleteElement(-1);
        }}
        onSuccess={async () => {
          setLoading(true);
          await deleteAccountInApi({ id: accounts[deleteElement].id });
          await getAccounts();
          setDeleteElement(-1);
          setLoading(false);
        }}
      >
        <span>
          {t("accounts.messages.DeleteText", { val: accounts[deleteElement]?.first_name + " " + accounts[deleteElement]?.last_name })}
        </span>
      </StaffModal>
    </div>
  );
}

export default Accounts;

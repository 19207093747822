import { Route, Routes, Navigate, Link, useLocation } from "react-router-dom";
import StaffLayout from "./components/StaffLayout";
import BoatManager from "./pages/staff/BoatManager";
import BoatOverview from "./pages/staff/BoatOverview";
import BoatTypeOverview from "./pages/staff/BoatTypeOverview";
import StaffLogin from "./pages/staff/StaffLogin";
import StatisticOverview from "./pages/staff/Statistics";
import Sports from "./pages/staff/Sports";
import Accounts from "./pages/staff/Accounts";
import { useEffect, useState } from "react";
import { getCurrentUser } from "./api/user";
import Comments from "./pages/staff/Comments";
import SingleStatisticSport from "./pages/staff/SingleStatisticSport";
import SingleStatisticBoatType from "./pages/staff/SingleStatisticBoattype";
import AllRents from "./pages/staff/AllRents";
import { Roles, isValidRole } from "./components/roles";

import UserFrontend from "./UserFrontend";
import AccessoryTypeOverview from "./pages/staff/AccessoryTypeOverview";
import AccessoryOverview from "./pages/staff/AccessoryOverview";

function Router() {
  const [role, setRole] = useState<Roles | null>(null);
  const [loginName, setLoginName] = useState<string | null>(null);
  const [loginID, setLoginID] = useState<string | null>(null);

  async function validateCurrentUser() {
    try {
      const response = await getCurrentUser();

      if (response.status === 200) {
        const json = await response.json();

        if (!isValidRole(json.result.role)) {
          setRole(null);
          return;
        }

        setRole(json.result.role);
        setLoginName(json.result.first_name);
        setLoginID(json.result.id);
      } else {
        setRole(null);
      }
    } catch (e) { }
  }

  useEffect(() => {
    validateCurrentUser();
  });

  // This is defined here instead of in UserFrontend so that it can access the validateCurrentUser functions
  let getAdminLoginElement = () => (
    // If you are not a boatManager or coordinator, you need to log in first!
    [Roles.terminalUser, null].includes(role) ? (
      <StaffLogin
        onChange={async () => {
          await validateCurrentUser();
        }}
      />
    ) : (
      <Navigate to="/staff" />
    )
  );

  return (
    <Routes>

      {/* The UserFrontend exists two times, once under `/`, and once under `/terminal/`
          The Frontend under `/terminal/` is mostly the same as the default one, but includes some different functionalities to accomodate many different people using it at once:
            1. The Homepage displays an additional button, leading to a list of all checked out boats.
            2. TODO: If a checkout-cookie is set, it doesn't automatically trigger the checkout site to appear.

          Theoretically, react-router should allow this differentiation with the optional route specification `terminal?/`, but I sadly couldn't get this to work at all,
          so this is the workaround.
      */}

      <Route path="terminal/*" element={
        role !== null ? (
          <UserFrontend getAdminLoginElement={getAdminLoginElement} role={role} />
        ) : (
          <Navigate to="/login" />
        )
      } />

      <Route path="*" element={
        role === Roles.terminalUser ? (
          <Navigate to="/terminal" />
        ) : (
          <UserFrontend getAdminLoginElement={getAdminLoginElement} role={role} />
        )
      } />

      <Route
        path="staff"
        element={
          !([Roles.terminalUser, null].includes(role)) ? (
            // If you are coordinator or boatManager, to the AdminFrontend 
            <StaffLayout
              isCoord={role === Roles.coordinator}
              onChange={async () => {
                await validateCurrentUser();
              }}
              loginName={loginName}
            />
          ) : role === Roles.terminalUser ? (
            // If you are a TerminalUser, to the Terminal
            <Navigate to="/terminal" />
          ) : (
            // If you are none (so not logged in), go to the login page
            <Navigate to="/login" />
          )
        }
      >
        <Route path="" element={<Navigate to="/staff/overview" />} />
        <Route path="overview" element={<BoatOverview />} />
        <Route path="boattypes" element={<BoatTypeOverview />} />
        <Route path="sports" element={<Sports />} />
        <Route path="boats" element={<BoatManager />} />
        <Route path="statistics" >
          <Route path="" element={<StatisticOverview />}> </Route>
          <Route path="sport">
            <Route path=":id" element={<SingleStatisticSport />} />
          </Route>
          <Route path="boattype">
            <Route path=":id" element={<SingleStatisticBoatType />} />
          </Route>
        </Route>
        <Route path="comments" element={<Comments />} />
        <Route path="allRents" element={<AllRents />} />
        <Route path="accessorytypes" element={<AccessoryTypeOverview />} />
        <Route path="accessories" element={<AccessoryOverview />} />
        <Route
          path="accounts"
          element={role === Roles.coordinator ? <Accounts loginID={loginID} /> : <Navigate to="/staff" />}
        />
      </Route>
    </Routes>
  );
}

export default Router;

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";


function TableSearch(props: { state: string, onChange: (val: string) => void }) {

    return (
        <div style={{ minWidth: "4em", width: "50%" }}>
            <Form.Control
                type="text" defaultValue={props.state}
                className="form-control-sm"
                placeholder="Search ..."
                aria-label="Search"
                onChange={(event) => {
                    props.onChange(event.target.value);
                }}
            />
        </div>
    );
}

export default TableSearch;

import { useEffect, useState, useRef } from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowUp, faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StaffModal from "../../components/StaffModal";
import { Form } from "react-bootstrap";
import { Controller, useForm, useFieldArray, ValidateResult } from "react-hook-form";
import { BoatType, Sport } from "../../types";
import {
    deleteBoatType,
    getBoatTypesFromApi,
    addBoatType,
    editBoatType,
} from "../../api/boatTypes";
import { getSportsFromApi } from "../../api/sport";
import { NavLink } from "react-router-dom";
import TableSearch from "../../components/TableSearch";


function BoatTypeOverview() {
    let mounted = useRef(true);
    const { t } = useTranslation();
    const [editElement, setEditElement] = useState<BoatType | undefined>(
        undefined
    );
    const [deleteElement, setDeleteElement] = useState<number>(-1);
    const [isAdding, setAdding] = useState<boolean>(false);
    const [boatTypes, setBoatTypes] = useState<BoatType[]>([]);
    const [sports, setSports] = useState<Sport[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [typeNameSearch, setTypeNameSearch] = useState<string>("");
    const [typeSportSearch, setTypeSportSearch] = useState<string>("");
    const [typeSeatsSearch, setTypeSeatsSearch] = useState<string>("");
    const [maxTimeSearch, setMaxTimeSearch] = useState<string>("");

    const [sortBy, setSortBy] = useState<string>("name");
    const [isAsc, setIsAsc] = useState<boolean>(false);

    const zeroPad = (num: number, places: number) => String(num).padStart(places, "0");

    function setSort(by: string) {
        if (sortBy !== by) {
            setSortBy(by);
            setIsAsc(false);
        } else {
            setIsAsc(!isAsc);
        }
    }

    function getSportString(sports: Sport[] | undefined): string {
        return sports?.map((sport) => sport.name)
          .sort((a, b) => a.localeCompare(b))
          .join(", ") || ""
    }

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
        reset,
    } = useForm<BoatType>({
        mode: "onChange",
        reValidateMode: 'onChange'
    });

    const {
        fields: sportField,
        append: appendSport,
        remove: removeSport,
        replace: replaceSport
    } = useFieldArray({
        control,
        name: "Sports",
    });

    async function getBoatTypes() {
        let boatTypes = await getBoatTypesFromApi();
        if (mounted) {
            setBoatTypes(boatTypes.result);
        }
    }
    async function getSports() {
        let sports = await getSportsFromApi();
        if (mounted) {
            setSports(sports);
        }
    }

    useEffect(() => {
        getBoatTypes();
        getSports();
        return () => {
            mounted.current = false;
        }
    }, []);

    return (
        <div className="m-1 h-100">
            <Table responsive striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            <div className="header">
                                <span onClick={() => { setSort("name") }}>
                                    {t("boatTypeOverview.Name")}
                                    {sortBy === "name" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{visibility: "hidden"}} /> }
                                </span>
                                <TableSearch state={typeNameSearch} onChange={(data) => { setTypeNameSearch(data) }} />
                            </div>
                        </th>
                        <th>
                            <div className="header">
                                <span onClick={() => { setSort("seats") }}>
                                    {t("boatTypeOverview.Seats")}
                                    {sortBy === "seats" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{visibility: "hidden"}} /> }
                                </span>
                                <TableSearch state={typeSeatsSearch} onChange={(data) => { setTypeSeatsSearch(data) }} />
                            </div>
                        </th>
                        <th>
                            <div className="header">
                                <span onClick={() => { setSort("maxTime") }}>
                                    {t("boatManager.MaxTime")}
                                    {sortBy === "maxTime" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{visibility: "hidden"}} /> }
                                </span>
                                <TableSearch state={maxTimeSearch} onChange={(data) => { setMaxTimeSearch(data) }} />
                            </div>
                        </th>
                        <th>
                            <div className="header">
                                <span onClick={() => {setSort("sport") }}>
                                    {t("boatTypeOverview.Sport")}
                                    {sortBy === "sport"? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{visibility: "hidden"}} />}
                                </span>
                                <TableSearch state={typeSportSearch} onChange={(data) => { setTypeSportSearch(data) }} />
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{ overflowY: "scroll", maxHeight: "100px" }}>
                    {!boatTypes ? <div></div> :
                        boatTypes
                            .sort((a, b) => {
                                if (sortBy === "maxTime") {
                                    return (a.maxTime - b.maxTime) * (isAsc ? 1 : -1);
                                }
                                if (sortBy === "sport") {
                                    return (getSportString(b.Sports)
                                        .localeCompare(getSportString(a.Sports)) || 0) * (isAsc ? 1 : -1);
                                }
                                return (b[sortBy as keyof BoatType]?.toString().localeCompare(a[sortBy as keyof BoatType]?.toString() || "") || 0) * (isAsc ? 1 : -1)
                            })
                            .filter((boatType) => boatType.name.toLocaleLowerCase().includes(typeNameSearch.toLocaleLowerCase()))
                            .filter((boatType) => boatType.Sports.map(x => x.name).join(', ').toLocaleLowerCase().includes(typeSportSearch.toLocaleLowerCase()))
                            .filter((boatType) => typeSeatsSearch === "" || Number(boatType.seats) === Number(typeSeatsSearch))
                            .filter((x) => maxTimeSearch === "" || ((x.maxTime === null || x.maxTime === 0 ? t("boatManager.Unlimited") : zeroPad(x.maxTimeHours || 0, 2) + ":" + zeroPad(x.maxTimeMinutes || 0, 2)).includes(maxTimeSearch)))
                            .map((x) => (
                                <tr key={x.id}
                                    style={{ cursor: "pointer", backgroundColor: x.Sports.length === 0 ? "#ffd0d0" : "#ffffff" }}
                                    onClick={() => {
                                        reset();
                                        setLoading(false);
                                        setEditElement(x);
                                        setValue("id", x.id);
                                        setValue("name", x.name);
                                        setValue("seats", x.seats);
                                        setValue("maxTimeHours", x.maxTimeHours);
                                        setValue("maxTimeMinutes", x.maxTimeMinutes);
                                        replaceSport(x.Sports.length > 0 ? x.Sports : { id: "", name: "", color: "" });
                                    }}>
                                    <td>
                                        {x.name}
                                    </td>
                                    <td>{x.seats}</td>
                                    <td>{x.maxTime === null || x.maxTime === 0 ? t("boatManager.Unlimited") : zeroPad(x.maxTimeHours || 0, 2) + ":" + zeroPad(x.maxTimeMinutes || 0, 2)}</td>
                                    <td>{getSportString(x.Sports)}</td>
                                    <td>
                                        <div className="d-flex">
                                            <NavLink to={"/staff/statistics/boattype/" + x.id}>
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className="text-secondary clickableIcon"
                                                    size="1x"
                                                />
                                            </NavLink>
                                            <div
                                                className="mx-2"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setDeleteElement(boatTypes.indexOf(x));
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                    className="text-danger clickableIcon"
                                                    size="1x"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                </tbody>
            </Table>
            <div
                className="d-flex px-2 py-1 justify-content-end bg-white border-top"
                style={{ position: "sticky", right: "5px", bottom: "0", zIndex: 3 }}
            >
                <Button
                    onClick={() => {
                        reset();
                        setAdding(true);
                        setEditElement({
                            id: "",
                            name: "",
                            seats: 1,
                            Sports: [{ id: undefined as any }],
                        } as BoatType);
                        setValue("id", undefined as any);
                        setValue("name", "");
                        setValue("seats", 1);
                        setValue("maxTimeHours", 2);
                        setValue("maxTimeMinutes", 0);
                        replaceSport([{ id: "", name: "", color: "" }]);
                    }}
                    variant="secondary"
                >
                    <FontAwesomeIcon icon={faPlus} className="text-white me-2" />
                    {t("boatTypeOverview.addBoatType")}
                </Button>
            </div>
            <StaffModal
                header={
                    isAdding
                        ? t("boatTypeOverview.addBoatType")
                        : t("boatTypeOverview.EditBoatType")
                }
                show={!!editElement}
                successText={
                    isAdding ? t("common.Add") : t("common.Edit")
                }
                disableNext={!isValid}
                loadingNext={loading}
                onHide={() => {
                    setEditElement(undefined);
                    setAdding(false);
                }}
                onSuccess={() => {
                    setLoading(true);
                    handleSubmit((data) => {
                        if (isAdding) {
                            addBoatType(data).then(async () => {
                                await getBoatTypes();
                                setEditElement(undefined);
                                setAdding(false);
                                setLoading(false);
                            }).catch(() => {
                                setLoading(false);
                            });
                        } else {
                            editBoatType(data).then(async () => {
                                await getBoatTypes();
                                setEditElement(undefined);
                                setAdding(false);
                                setLoading(false);
                            }).catch(() => {
                                setLoading(false);
                            });
                        }
                    })();
                    setLoading(false);
                }}
            >
                <Form>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={editElement?.name}
                        render={({ field }) => (
                            <div className="mb-2">
                                <Form.Label>{t("boatTypeOverview.Name")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...field}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name?.message}
                                </Form.Control.Feedback>
                            </div>
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: t("common.messages.required", {
                                    val: t("boatTypeOverview.Name"),
                                }),
                            },
                            validate: (value: string): ValidateResult => {
                                const isRedundant = boatTypes.some((boatType) => boatType.name.trim() === value.trim() && boatType.id !== editElement?.id);
                                //Redundancy Check
                                if ( isRedundant ) {
                                    return t("common.messages.nameExists") as ValidateResult;
                                }
                                //Empty String Check, You need 'val:..' for '{{val}}' in json.
                                if ( value.trim() === "" || value.trim().length === 0) {
                                    return t("common.messages.required", {
                                      val: t("boatTypeOverview.Name"),
                                    }) as ValidateResult;
                                }                                
                            },
                        }}
                    />
                    <Controller
                        name="seats"
                        control={control}
                        defaultValue={editElement?.seats}
                        render={({ field }) => (
                            <div className="mb-2">
                                <Form.Label>{t("boatTypeOverview.Seats")}</Form.Label>
                                <Form.Control
                                    type="number"
                                    min="1"
                                    {...field}
                                    isInvalid={!!errors.seats}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.seats?.message}
                                </Form.Control.Feedback>
                            </div>
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: t("common.messages.required", {
                                    val: t("boatTypeOverview.Seats"),
                                }),
                            },
                        }}
                    />
                    <Row>
                        <Col>
                            <Controller
                                name="maxTimeHours"
                                rules={{ min: 0 }}
                                control={control}
                                defaultValue={editElement?.maxTimeHours}
                                render={({ field }) => (
                                    <div className="mb-2">
                                        <Form.Label>{t("boatManager.MaxTimeHours")}</Form.Label>
                                        <Form.Control type="number" {...field} min={0} />
                                    </div>
                                )}
                            />
                        </Col>
                        <Col>
                            <Controller
                                name="maxTimeMinutes"
                                rules={{ min: 0, max: 59 }}
                                control={control}
                                defaultValue={editElement?.maxTimeMinutes}
                                render={({ field }) => (
                                    <div className="mb-2">
                                        <Form.Label>{t("boatManager.MaxTimeMinutes")}</Form.Label>
                                        <Form.Control type="number" {...field} min={0} max={59} />
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between">
                        <h5>{t("boatTypeOverview.Sports")}</h5>
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={() => appendSport({ id: "", name: "", color: "" })}
                        >
                            +
                        </Button>
                    </div>

                    <ul>
                        {sportField.map((item: any, index: number) => (
                            <div key={item.id}>
                                <div className="d-flex my-2">
                                    <Controller
                                        name={`Sports.${index}.id` as const}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Select {...field} isInvalid={!!errors.Sports?.[index]?.id}>
                                                <option></option>
                                                {sports
                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                    .map((x) => (
                                                    <option key={x.id} value={x.id}>
                                                        {x.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: t("common.messages.required", {
                                                    val: t("boatTypeOverview.Sport"),
                                                }),
                                            },
                                        }}
                                    />
                                    <Button
                                        className="mx-3"
                                        variant="danger"
                                        type="button"
                                        onClick={() => removeSport(index)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            className="text-white"
                                        />
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </ul>
                </Form>
            </StaffModal>
            <StaffModal
                header={t("boatTypeOverview.DeleteBoatType")}
                hideColor="secondary"
                successText={t("common.Delete")}
                successColor="danger"
                show={deleteElement !== -1}
                loadingNext={loading}
                onHide={() => {
                    setDeleteElement(-1);
                }}
                onSuccess={async () => {
                    setLoading(true);
                    await deleteBoatType({ id: boatTypes[deleteElement].id });
                    await getBoatTypes();
                    setDeleteElement(-1);
                    setLoading(false);
                }}
            >
                <span>
                    {!boatTypes ? <div></div> : t("boatTypeOverview.messages.DeleteText", {
                        val: boatTypes[deleteElement]?.name,
                    })}
                </span>
            </StaffModal>
        </div>
    );
}

export default BoatTypeOverview;

import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import LanguageSelector from "../components/LanguageSelector";
import { serverurl } from "./serverurl";


function UserLayout() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div>
      <Helmet>
        <title>{pathname.includes('checkout') ? t('routes./checkout') : t(`routes.${pathname}`)}</title>
      </Helmet>
      <div
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${serverurl + "static-instance/bg_mobile.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          zIndex: -1,
        }}
      />
      <div className="language-div">
        <div style={{ float: "right", right: "1%", top: "1%", position: "fixed" }}>
          <LanguageSelector ></LanguageSelector>
        </div>
      </div>

      <Outlet />
    </div>
  );
}

export default UserLayout;

import { faLock, faExclamationTriangle, faCircle, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";


function StatusIcon(props: { status: number }) {
    const { t } = useTranslation();

    return (
        <div style={{display: "flex"}}>
            {props.status === 0 && <><div style={{ width: "24px", height: "24px", textAlign: "center" }}> <FontAwesomeIcon icon={faCircle} className={"text-success"} /></div> {t("boatManager.available")}</>}
            {props.status === 1 && <><div style={{ width: "24px", height: "24px", textAlign: "center" }}> <FontAwesomeIcon icon={faLock} className={"text-info"} /></div> {t("boatManager.locked")}</>}
            {props.status === 2 && <><div style={{ width: "24px", height: "24px", textAlign: "center" }}> <FontAwesomeIcon icon={faMinusSquare} className={"text-secondary"} /></div> {t("boatManager.rented")}</ >}
            {props.status === 3 && <><div style={{ width: "24px", height: "24px", textAlign: "center" }}> <FontAwesomeIcon icon={faExclamationTriangle} className={"text-danger"} /> </div>{t("boatManager.overdue")}</ >}
        </div>
    );
}
export default StatusIcon;

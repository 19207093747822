import { Sport, ID } from "../types"

export async function getSport(id:string) {
  const response = await fetch(`/api/sports/${id}/`, {
    method: "GET",
  });
  const json = await response.json();
  return json;
}

export async function getSportsFromApi() {
  const response = await fetch("/api/sport", {
    method: "GET",
  });
  const json = await response.json();
  return json.result;
}

export async function addSport({ name, color }: Omit<Sport, "id">) {
  const response = await fetch("/api/sport", {
    method: "POST",
    body: JSON.stringify({
      name,
      color
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function editSport({ id, name, color }: Sport) {
  const response = await fetch(`/api/sport/${id}`, {
    method: "PATCH",
    body: JSON.stringify({
      name,
      color,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function deleteSportInApi({ id }: ID) {
  const response = await fetch(`/api/sport/${id}`, {
    method: "DELETE",
  });
  const json = await response.json();
  return json;
}

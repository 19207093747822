import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import LanguageSelector from "../components/LanguageSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function StaffLayout(props: any) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  async function logout() {
    try {
      await fetch("/api/logout", {
        method: "POST",
      });
      navigate("/");
      props.onChange()
    } catch (e) {

      console.error(e);
    }
  }


  return (
    <div className="h-100 w-100" style={{ "overflow": "none" }}>
      <Helmet>
        <title>{t(`routes.${pathname}`)}</title>
      </Helmet>
      <Navbar sticky="top" className="bg-secondary" variant="dark" expand="lg">
        <Container style={{ maxWidth: "none" }}>
          <Navbar.Brand className="fw-bold" style={{ minWidth: "200px" }}>
            {pathname.includes("/statistics/sport") ? t('statistics.r') : (pathname.includes("statistics/boattype/") ? t(`statistics.s`) : t(`routes.${pathname}`))}
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="mr-auto d-flex justify-content-between w-100">
              <div className="d-flex menuNoWrap">
                <Nav activeKey={pathname}>
                  <Nav.Link as={NavLink} to="/staff/overview">
                    {t("staffNav.buttonBoatOverview")}
                  </Nav.Link>
                  <NavDropdown title={t("staffNav.dropdown.Boats")}>
                    <NavDropdown.Item as={NavLink} to="/staff/boattypes">{t("staffNav.buttonBoatTypes")}</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/staff/boats">{t("staffNav.buttonManageBoats")}</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title={t("staffNav.dropdown.Accessories")}>
                    <NavDropdown.Item as={NavLink} to="/staff/accessorytypes">{t("staffNav.accessorytypes")}</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/staff/accessories">{t("staffNav.accessories")}</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title={t("staffNav.dropdown.Rents")}>
                    <NavDropdown.Item as={NavLink} to="/staff/allRents">{t("staffNav.allRents")}</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/staff/comments">{t("staffNav.comments")}</NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link as={NavLink} to="/staff/sports">
                    {t("staffNav.buttonSports")}
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/staff/statistics">
                    {t("staffNav.buttonStatistics")}
                  </Nav.Link>
                  {props.isCoord && <Nav.Link as={NavLink} to="/staff/accounts">
                    {t("staffNav.buttonAccounts")}
                  </Nav.Link>}

                </Nav>
              </div>
              <div style={{ width: "100%" }} />
              <div className="nav-link disable-hover menuNoWrap">
                {t("staffNav.loginAs")} {props.loginName}
              </div>
              <div>
                <Nav.Link
                  onClick={() => {
                    logout();
                  }}
                >
                  {t("staffNav.buttonLogout")}
                </Nav.Link>
              </div>
              <div>
                <Nav>
                  <LanguageSelector></LanguageSelector>
                </Nav>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ "overflow": "auto", "maxHeight": "calc(100vh - 56px)" }}>
        <Outlet />
      </div>
    </div>
  );
}

export default StaffLayout;

import { Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Divider from "../layout/Divider";

type FormData = {
  sport: string;
  boatName: string;
  Accessory: string;
  startTime: string;
  estimatedEndTime: string;
  destination: string;
  name: string;
  isCourse: string;
  email: string;
  annotations: string;
};

function BoatInfo(props: any) {
  const {
    control,
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: 'onChange'
  });
  const { t } = useTranslation();
  const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, "0");
  return (
    <Form>
      <Row>
        <Controller
          name="boatName"
          control={control}
          defaultValue={props.boatName}
          render={({ field }) => (
            <div className="mb-2 col">
              <Form.Label>{t("bookingForm.labelBoatName")}</Form.Label>
              <Form.Control disabled type="text" {...field} />
            </div>
          )}
        />
        <Controller
          name="sport"
          control={control}
          defaultValue={props.sport}
          render={({ field }) => (
            <div className="mb-2 col">
              <Form.Label >{t("bookingForm.labelSport")}</Form.Label>
              <Form.Control disabled type="text" {...field} />
            </div>
          )}
        />
      </Row>
      {props.Accessory ? (
        <Row>
          <Controller
            name="Accessory"
            control={control}
            defaultValue={props.Accessory.name}
            render={({ field }) => (
              <div className="mb-2 col">
                <Form.Label>{t("bookingForm.labelAccessory")}</Form.Label>
                <Form.Control disabled type="text" {...field} />
              </div>
            )}
          />
        </Row>
      ) : <></>}
      <Row>
        <Controller
          name="startTime"
          control={control}
          defaultValue={zeroPad(new Date(props.startTime).getHours(), 2) + ":" + zeroPad(new Date(props.startTime).getMinutes(), 2)}
          render={({ field }) => (
            <div className="mb-2 col">
              <Form.Label>{t("bookingForm.labelStartTime")}</Form.Label>
              <Form.Control disabled type="time" {...field} />
            </div>
          )}

        />
        <Controller
          name="estimatedEndTime"
          control={control}
          defaultValue={zeroPad(new Date(props.estimatedEndTime).getHours(), 2) + ":" + zeroPad(new Date(props.estimatedEndTime).getMinutes(), 2)}
          render={({ field }) => (
            <div className="mb-2 col">
              <Form.Label>{t("bookingForm.labelEstimatedEndTime")}</Form.Label>
              <Form.Control disabled type="time" {...field} />
            </div>
          )}
        />
      </Row>
      <Controller
        name="destination"
        control={control}
        defaultValue={props.destination}
        render={({ field }) => (
          <div className="mb-2">
            <Form.Label>{t("bookingForm.labelDestination")}</Form.Label>
            <Form.Control disabled type="text" {...field} />
          </div>
        )}
      />
      <Divider />
      <Controller
        name="isCourse"
        control={control}
        defaultValue={props.isCourse}
        render={({ field }) => (
          <div className="mb-2">
            <Form.Check
              type="checkbox"
              id="isCourse"
              name={field.name}
              disabled={true}
              defaultChecked={props.isCourse}
              inline
            />
            <Form.Label>{t("allRents.isCourse")}</Form.Label>
          </div>
        )}
      />
      <Divider />
      <Controller
        name="name"
        control={control}
        defaultValue={props.fullNameOfResponsibleClient}
        render={({ field }) => (
          <div className="mb-2">
            <Form.Label>{t("bookingForm.labelName")}</Form.Label>
            <Form.Control disabled type="text" {...field} />
          </div>
        )}
      />
      {props.additionalClients !== undefined && props.additionalClients.length > 0 && <div className="d-flex justify-content-between">
        <h5>{t("bookingForm.labelAdditionalNames")}</h5>
      </div>}

      {props.additionalClients !== undefined && props.additionalClients.length > 0 && <ul>
        {props.additionalClients.map((item: any, index: number) => (
          <div key={index}>
            <div className="d-flex my-2">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Control
                      value={item}
                      disabled
                      type="text"
                    />

                  </>
                )}
              />
            </div>
          </div>))}
      </ul>}
    </Form>
  );
}

export default BoatInfo;

import { Route, Routes, Navigate, useLocation, Router } from "react-router-dom";
import UserLayout from "./components/UserLayout";
import BookingForm from "./pages/public/BookingForm";
import BookingSignOut from "./pages/public/BookingSignOut";
import BookingSignOutSuccess from "./pages/public/BookingSignOutSuccess";
import BookingSuccessful from "./pages/public/BookingSuccessful";
import BoatList from "./pages/public/BoatList";
import Home from "./pages/public/Home";
import { Roles } from "./components/roles";
import { useEffect, useState } from "react";
import { getCurrentCheckIn } from "./api/checkIn";


function UserFrontend(props: any) {
    /** This Element contains all Routes of the Userfrontend, excluding the Admin-Login site.
     * 
     * It is separated from the other Routes in Router.tsx, because these Routes need to both exist on their own, and under the `/terminal/` URL.
     * This way, the sites can be accessed both in the terminal and on the standalone site.
     *  
     * The Home Element needs to know whether it is in the terminal, and display the Button to the Checkout List depending on that.
     * So, props includes a boolean indicating whether this is the terminal or not.
     * 
     * props also includes the argument `props.adminLoginElement`, which includes the logic leading to the Login page.
     * This is separated from the rest of the UserFrontend, because it depends on the validateCurrentUser-function present in Router.tsx.
     * 
     */
    const [checkInCookie, setCheckInCookie] = useState<string | null>(null);

    let getAdminLoginElement = props.getAdminLoginElement;
    let role = props.role;

    // Variable never needs to be actually used.
    // It simply being set leads to the whole App Component rerendering, also updating the CheckInCookie status
    // Which actually is what we want from this. Suboptimal, because the entire App is rerendered everytime.
    const location = useLocation().pathname;

    async function checkForCheckInCookie() {

        if (role === Roles.terminalUser) {
            setCheckInCookie(null);
            return
        }

        if (!document.cookie.includes("checkin_token")) {
            // If no cookie is set, it's useless to send a request to the server
            // Since the server can only check whether our cookie is valid or not.
            setCheckInCookie(null);
            return
        }

        try {
            const response = await getCurrentCheckIn();
            if (response.status === 200) {
                const json = await response.json();
                setCheckInCookie(json.result);
            } else {
                setCheckInCookie(null);
            }
        } catch (e) {
            setCheckInCookie(null);
        }
    }

    useEffect(() => {
        checkForCheckInCookie();
    });


    return (
        <Routes>
            <Route path="/" element={<UserLayout />}>


                {/* Home page (user) */}
                <Route
                    index
                    element={
                        checkInCookie ? (
                            <Navigate to={"checkout/" + checkInCookie} />
                        ) : (
                            <Home role={role} />
                        )
                    }
                />


                {/* Book a boat (user) */}
                <Route path="checkin">
                    <Route index element={<BookingForm />} />
                    {/* After booking (user) */}
                    <Route path="success" element={<BookingSuccessful />} />
                </Route>


                {/* Sign out (user) */}
                <Route path="checkout">
                    <Route
                        index
                        element={
                            checkInCookie ? (
                                <Navigate to={"./" + checkInCookie} />
                            ) : (
                                <Navigate to={"../"} />
                            )
                        }
                    />
                    <Route path=":id" element={
                        checkInCookie || role === Roles.terminalUser ? (
                            <BookingSignOut />
                        ) : (
                            <Navigate to={"../../"} />
                        )
                    } />
                    {/* After Sign out (user) */}
                    <Route path="success" element={<BookingSignOutSuccess />} />
                </Route>


                {role === Roles.terminalUser ?
                    <Route path="return" element={<BoatList />} />
                    :
                    <></>
                }

                <Route
                    path="login"
                    element={getAdminLoginElement()}
                />

            </Route>
        </Routes>
    )
}

export default UserFrontend;
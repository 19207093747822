import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Divider from "../../components/layout/Divider";
import Modal from "../../components/Modal";

function BookingSignOutSuccess() {
  const { t } = useTranslation(); //i18n

  return (
    <Container className="pt-5">
      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <Modal>
            <h1 className="text-center">{t("bookingSignOutSuccess.title")}</h1>
            <div className="text-center">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-primary"
                size="6x"
              />
            </div>
            <Divider />
            <p className="my-5">
              {t("bookingSignOutSuccess.labelSignOutInfo")}
            </p>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default BookingSignOutSuccess;

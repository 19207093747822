import { Boat, ID, OverviewBoat } from "../types"

export async function getBoatsOverview(): Promise<OverviewBoat[]> {
  const response = await fetch("/api/boatoverview", {
    method: "GET",
  });
  const json = await response.json();
  return json.result;
}

export async function getBoatsFromApi() {
  const response = await fetch("/api/boat", {
    method: "GET",
  });
  const json = await response.json();
  return json;
}

export async function addBoat({
  name,
  boattype,
  status,
}: Omit<Boat, "id">) {
  const response = await fetch("/api/boat", {
    method: "POST",
    body: JSON.stringify({
      name: name,
      boattype: boattype,
      status: status || 0,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function editBoat({
  id,
  name,
  boattype,
  status,
}: Boat) {
  const response = await fetch(`/api/boat/${id}`, {
    method: "PATCH",
    body: JSON.stringify({
      name,
      boattype,
      status: status || 0,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function deleteBoat({ id }: ID) {
  const response = await fetch(`/api/boat/${id}`, {
    method: "DELETE",
  });
  const json = await response.json();
  return json;
}

export async function lock({ id }: ID) {
  const response = await fetch(`/api/lock/${id}`, {
    method: "POST",
  });
  const json = await response.json();
  return json;
}

export async function unlock({ id }: ID) {
  const response = await fetch(`/api/unlock/${id}`, {
    method: "POST",
  });
  const json = await response.json();
  return json;
}

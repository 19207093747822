import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Divider from "../../components/layout/Divider";
import Modal from "../../components/Modal";

function BookingSuccessful() {
  const { t } = useTranslation(); // i18n

  return (
    <Container className="pt-5">
      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <Modal>
            <h1 className="text-center p-1">{t("bookingSuccessful.title")}</h1>
            <Divider />
            <div className="text-center m-4">
              <FontAwesomeIcon
                size="6x"
                className="text-primary"
                icon={faCheckCircle}
              />
            </div>
            <div className="bg-light border p-2 mlr-4 text-center">
              <h2 className="m-2">
                {t("bookingSuccessful.boxImportantTitle")}
              </h2>
              <p>{t("bookingSuccessful.boxImportantText")}</p>
            </div>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default BookingSuccessful;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function LanguageSlector() {
    const { i18n } = useTranslation();

    return <Dropdown align="end">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            <FontAwesomeIcon icon={faGlobe} className="changeIcon" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <Dropdown.Item onClick={() => { i18n.changeLanguage("de") }}>
                {<FontAwesomeIcon icon={faCheck} style={{ marginRight: "10px", visibility: i18n.language === "de" ? "visible" : "hidden" }} />}
                Deutsch
            </Dropdown.Item>
            <Dropdown.Item onClick={() => { i18n.changeLanguage("en") }}>
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: "10px", visibility: i18n.language === "en" ? "visible" : "hidden" }} />
                English
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
}
export default LanguageSlector
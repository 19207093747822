import { BoatType, ID } from "../types"
export async function getBoatTypesFromApi() {
  const response = await fetch("/api/boattype", {
    method: "GET",
  });
  const json = await response.json();
  if (json.result) {
    json.result = json.result.map((x: BoatType) => { return Object.assign(x, { maxTimeHours: Math.floor(x.maxTime || 0), maxTimeMinutes: Number(((x.maxTime - Math.floor(x.maxTime || 0)) * 60).toFixed(0)) }) })
  }
  return json;
}

export async function addBoatType({
  name,
  seats,
  Sports,
  maxTimeMinutes,
  maxTimeHours
}: Omit<BoatType, "id">) {
  const response = await fetch("/api/boattype", {
    method: "POST",
    body: JSON.stringify({
      name,
      seats,
      maxTime: (maxTimeHours || maxTimeMinutes) ? (Number(maxTimeHours) || 0) + (Number(maxTimeMinutes) || 0) / 60 : 0,
      sports: Sports,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function editBoatType({
  id,
  name,
  seats,
  Sports,
  maxTimeMinutes,
  maxTimeHours
}: BoatType) {
  
  let hours: number = Number(maxTimeHours);
  let mins: number = Number(maxTimeMinutes);

  const response = await fetch(`/api/boattype/${id}`, {
    method: "PATCH",
    body: JSON.stringify({
      name,
      seats,
      sports: Sports,
      maxTime: (hours !== null && mins !== null) ? hours + mins / 60 : 0,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function deleteBoatType({ id }: ID) {
  const response = await fetch(`/api/boattype/${id}`, {
    method: "DELETE",
  });
  const json = await response.json();
  return json;
}

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Loading from "./components/layout/Loading";
import "./i18n";
import Router from "./Router";
import "./styles/app.scss";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <HelmetProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </HelmetProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

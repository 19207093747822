import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFilter } from "@fortawesome/free-solid-svg-icons";

function TableFilter(props: { state: string[], onChange: (val: string[]) => void, options: { label: string, value: string }[] }) {
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <div className={"tableSearch " + (props.state.length < props.options.length || isOpen ? "active" : "")}>
            <FontAwesomeIcon onClick={() => { setOpen(!isOpen) }} icon={faFilter} className={"searchIcon"} inverse={props.state.length < props.options.length || isOpen}></FontAwesomeIcon>
            {isOpen &&
                <div className="popover">
                    {props.options.map(x => <div className="filterItem" key={x.value} onClick={()=>{
                        if (props.state.includes(x.value)){
                            props.onChange(props.state.filter(y=>y!==x.value))
                        } else {
                            props.onChange(props.state.concat([x.value]))
                        }
                    }}>
                        {props.state.includes(x.value)&&<FontAwesomeIcon icon={faCheck} className={"filterCheck"}></FontAwesomeIcon>}
                        {!props.state.includes(x.value)&&<div className={"filterCheck"} style={{width: "14px"}}></div>}
                        {x.label}
                    </div>)}
                </div>
            }
        </div>
    );
}

export default TableFilter;

import { useState, useEffect, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowUp, faPlus, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StaffModal from "../../components/StaffModal";
import { getAccessoriesFromApi, deleteAccessory, addAccessory, editAccessory } from "../../api/accessory";
import { getAccessoryTypesFromApi } from "../../api/accessoryTypes";
import { Accessory, AccessoryType, BoatType } from "../../types";
import { Form } from "react-bootstrap";
import StatusIcon from "../../components/StatusIcon";
import { Controller, ValidateResult, useForm } from "react-hook-form";
import TableFilter from "../../components/TableFilter";
import { NavLink } from "react-router-dom";
import TableSearch from "../../components/TableSearch";

function AccessoryOverview() {
  let mounted = useRef(true);
  const { t } = useTranslation();
  const [editElement, setEditElement] = useState<Accessory | undefined>(undefined);
  const [deleteElement, setDeleteElement] = useState<number>(-1);
  const [isAdding, setAdding] = useState<boolean>(false);
  const [accessories, setAccessories] = useState<Accessory[]>([]);
  const [accessoryTypes, setAccessoryTypes] = useState<AccessoryType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [accessoryNameSearch, setAccessoryNameSearch] = useState<string>("");
  const [accessoryTypeSearch, setAccessoryTypeSearch] = useState<string>("");
  const [accessoryStatusSearch, setAccessoryStatusSearch] = useState<string[]>(["0", "1", "2", "3"]);
  const [boatTypeSearch, setBoatTypeSearch] = useState<string>("");

  const [sortBy, setSortBy] = useState<string>("name");
  const [isAsc, setIsAsc] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<Accessory>({
    mode: "onChange",
    reValidateMode: 'onChange'
  });

  function setSort(by: string) {
    if (sortBy !== by) {
      setSortBy(by);
      setIsAsc(false);
    } else {
      setIsAsc(!isAsc);
    }
  }

  function getBoatTypeString(boatTypes: BoatType[] | undefined): string {
    return boatTypes?.map((boatType) => boatType.name)
      .sort((a, b) => a.localeCompare(b))
      .join(", ") || ""
  }

  const getAccessories = async () => {
    const accessories = (await getAccessoriesFromApi() || []);
    if (mounted) {
      setAccessories(accessories.result);
    }
  };
  async function getAccessoryTypes() {
    const accessoryTypes = (await getAccessoryTypesFromApi() || []);
    if (mounted) {
      setAccessoryTypes(accessoryTypes.result);
    }
  };

  useEffect(() => {
    getAccessories();
    getAccessoryTypes();
    return () => {
      mounted.current = false;
    }
  }, []);

  return (
    <div className="m-1 h-100">
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>
              <div className="header">
                <span onClick={() => { setSort("name") }}>
                  {t("accessoryOverview.AccessoryName")}
                  {sortBy === "name" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={accessoryNameSearch} onChange={(data) => { setAccessoryNameSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("boatTypes") }}>
                  {t("accessoryOverview.boatTypeName")}
                  {sortBy === "boatTypes" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={boatTypeSearch} onChange={(data) => { setBoatTypeSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">
                <span onClick={() => { setSort("accessorytype") }}>
                  {t("accessoryOverview.AccessoryType")}
                  {sortBy === "accessorytype" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableSearch state={accessoryTypeSearch} onChange={(data) => { setAccessoryTypeSearch(data) }} />
              </div>
            </th>
            <th>
              <div className="header">

                <span onClick={() => { setSort("status") }}>
                  {t("accessoryOverview.Status")}
                  {sortBy === "status" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{ visibility: "hidden" }} />}
                </span>
                <TableFilter options={[
                  { label: t("accessoryOverview.available"), value: "0" },
                  { label: t("accessoryOverview.locked"), value: "1" },
                  { label: t("accessoryOverview.rented"), value: "2" },
                  { label: t("accessoryOverview.overdue"), value: "3" }
                ]} state={accessoryStatusSearch} onChange={(data) => { setAccessoryStatusSearch(data) }}></TableFilter>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody style={{ overflowY: "scroll", maxHeight: "100px" }}>
          {accessories
            .map((b) => {
              b.accessorytype = b.accessorytype || "";
              return b;
            })
            .sort((a, b) => {
              if (sortBy === "accessorytype") {
                let accessoryTypeA = accessoryTypes.find(t => t.id === a.accessorytype);
                let accessoryTypeB = accessoryTypes.find(t => t.id === b.accessorytype);
                return (accessoryTypeB?.name || "").localeCompare((accessoryTypeA?.name || "")) * (isAsc ? 1 : -1);
              }
              if (sortBy === "boatTypes") {
                let boatTypeStringA = getBoatTypeString(accessoryTypes.find(t => t.id === a.accessorytype)?.BoatTypes);
                let boatTypeStringB = getBoatTypeString(accessoryTypes.find(t => t.id === b.accessorytype)?.BoatTypes);
                return boatTypeStringB.localeCompare(boatTypeStringA) * (isAsc ? 1 : -1);
              }
              return (b[sortBy as keyof Accessory]?.toString().localeCompare(a[sortBy as keyof Accessory]?.toString() || "") || 0) * (isAsc ? 1 : -1)
            })
            .filter((accessory) => accessory.name.toLocaleLowerCase().includes(accessoryNameSearch.toLocaleLowerCase()))
            .filter((accessory: any) => {
              if (accessory.accessorytype === undefined || accessory.accessorytypedata.BoatTypes.length === 0) {
                // If no AccessoryType, and therefore no BoatType exists, always show the accessory
                return true;
              }
              const boatTypeNames = accessory.accessorytypedata.BoatTypes.map((boatType: any) => boatType.name.toLowerCase());
              return boatTypeNames.some((name: any) => name.includes(boatTypeSearch.toLowerCase()));
            })
            .filter((accessory: any) => {
              if (accessory.accessorytype === undefined) {
                // If no AccessoryType exists, always show the accessory
                return true;
              }
              return accessory.accessorytypedata?.name.toLocaleLowerCase().includes(accessoryTypeSearch.toLocaleLowerCase())
            })
            .filter((accessory) => accessoryStatusSearch.includes(accessory.status.toString()))
            .map((x, i) => (
              <tr key={x.id}
                style={{ cursor: "pointer", backgroundColor: x.accessorytype === "" || (x as any).accessorytypedata?.BoatTypes.length === 0 ? "#ffd0d0" : "#ffffff" }}
                onClick={() => {
                  reset();
                  setEditElement(x);
                  setValue('id', x.id);
                  setValue('name', x.name);
                  setValue('status', x.status);
                  setValue('accessorytype', x.accessorytype);
                }}
              >
                <td>
                  {x.name}
                </td>
                <td>
                  {getBoatTypeString((x as any).accessorytypedata?.BoatTypes)}
                </td>
                <td>{(x as any).accessorytypedata?.name}</td>
                <td><StatusIcon status={x.status}></StatusIcon></td>
                <td>
                  <div className="d-inline-flex">
                    <NavLink to={"/staff/statistics/"}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="text-secondary clickableIcon"
                        size="1x"
                      />
                    </NavLink>
                    <div
                      className="mx-2"
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeleteElement(accessories.indexOf(x));
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="text-danger clickableIcon"
                        size="1x"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        className="d-flex px-2 py-1 justify-content-end bg-white border-top"
        style={{ position: "sticky", right: "5px", bottom: "0", zIndex: 3 }}
      >
        <Button
          onClick={() => {
            reset();
            setAdding(true);
            setEditElement({ id: "", name: "", "status": 0 } as Accessory);
            setValue('id', undefined as any);
            setValue('name', "");
            setValue('status', 0);
          }}
          variant="secondary"
        >
          <FontAwesomeIcon icon={faPlus} className="text-white me-2" />
          {t("accessoryOverview.AddAccessory")}
        </Button>
      </div>


      <StaffModal
        header={isAdding ? t("accessoryOverview.AddAccessory") : t("accessoryOverview.EditAccessory")}
        show={!!editElement}
        successText={isAdding ? t("common.Add") : t("common.Edit")}
        loadingNext={loading}
        disableNext={!isValid}
        onHide={() => {
          setEditElement(undefined);
          setAdding(false);
        }}
        onSuccess={() => {
          setLoading(true);
          handleSubmit((data) => {
            if (isAdding) {
              addAccessory(data).then(async () => {
                await getAccessories();
                setEditElement(undefined);
                setAdding(false);
                setLoading(false);
              });
            } else {
              editAccessory(data).then(async () => {
                await getAccessories();
                setEditElement(undefined);
                setAdding(false);
                setLoading(false);
              });
            }
          })()
        }}
      >
        <Form>
          <Controller
            name="name"
            control={control}
            defaultValue={editElement?.name}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accessoryOverview.AccessoryName")}</Form.Label>
                <Form.Control
                  type="text"
                  {...field}
                  isInvalid={!!errors.name}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("accessoryOverview.AccessoryName"),
                }),
              },
              validate: (value: string): ValidateResult => {
                const isRedundant = accessories.some((accessory) => accessory.name === value && accessory.id !== editElement?.id);
                if (isRedundant) {
                  return t("common.messages.nameExists") as ValidateResult;
                }
                return undefined;
              },
            }}
          />
          <Controller
            name="accessorytype"
            control={control}
            defaultValue={editElement?.accessorytype}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accessoryOverview.AccessoryType")}</Form.Label>
                <Form.Select aria-label="Default select example" {...field} isInvalid={!!errors.accessorytype}>
                  <option></option>
                  {accessoryTypes
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((x) => (
                      <option key={x.id} value={x.id}> {x.name} </option>
                    ))
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.accessorytype?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("accessoryOverview.AccessoryType"),
                }),
              },
            }}
          />
          <Controller
            name="status"
            control={control}
            defaultValue={editElement?.status}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("accessoryOverview.Status")}</Form.Label>
                <Form.Select aria-label="Default select example" {...field}>
                  <option value={0}>{t("accessoryOverview.available")}</option>
                  <option value={1}>{t("accessoryOverview.locked")}</option>
                </Form.Select>
              </div>
            )}
          />
        </Form>

      </StaffModal>


      <StaffModal
        header={t("accessoryOverview.DeleteAccessory")}
        hideColor="secondary"
        successText={t("common.Delete")}
        successColor="danger"
        show={deleteElement !== -1}
        loadingNext={loading}
        onHide={() => {
          setDeleteElement(-1);
        }}
        onSuccess={async () => {
          setLoading(true);
          await deleteAccessory({ id: accessories[deleteElement].id })
          await getAccessories();
          setDeleteElement(-1);
          setLoading(false);
        }}
      >
        <span>
          {t("accessoryOverview.messages.DeleteText", { val: accessories[deleteElement]?.name })}
        </span>
      </StaffModal>
    </div>
  );
}

export default AccessoryOverview;

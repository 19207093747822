import { useState } from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";


function BoatTile(props: any) {
    const [hover, setMouseHover] = useState<boolean>(false);
    return (
        <Col>
            <div className={`card-body shadow position-relative mb-1 rounded-2 ${props.isInUse ? (props.overdue ? 'bg-danger' : 'bg-secondary') : (props.blocked ? 'bg-info' : 'bg-success')} boatTile`}
                onClick={props.clickEvent} style={{ height: "110px" }}>
                <div className="text-uppercase fs-4 tile-label-boatName position-absolute ms-2 mt-1 top-0 start-0 text-white fw-bold"
                    style={{ maxWidth: "calc(100%-6em)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{props.boatName}</div>
                {props.time && <div className="tile-label-time position-absolute me-2 mt-2 top-0 end-0 text-white">{(new Date(props.time))?.getHours().toString().padStart(2, "0") + ':' + (new Date(props.time))?.getMinutes().toString().padStart(2, "0")}</div>}
                {props.accessory &&
                    <div className="tile-label-additionalClients position-absolute ms-2 start-0 text-white" style={{ marginTop: "1.2em" }}>{props.accessory}</div>}
                <div className="tile-label-additionalClients position-absolute ms-2 start-0 text-white" style={{ marginTop: "2.6em" }}>{props.isCourse}</div>
                <div className="tile-label-additionalClients position-absolute ms-2 mb-2 bottom-0 start-0 text-white">{props.additionalClients}</div>
                {/* Conditonally render the overdue text if it is set*/}
                {props.overdue &&
                    <div className="tile-label-overdue position-absolute me-2 mb-2 bottom-0 end-0 text-white fw-bold">Overdue!</div>
                }
                {props.blocked &&
                    <div className="tile-label-overdue position-absolute me-2 mb-2 bottom-0 end-0 text-white fw-bold">
                        <div className="mx-2" onMouseOver={() => { setMouseHover(true) }} onMouseLeave={() => { setMouseHover(false) }} onClick={(event) => { props.changeLock(); event.stopPropagation() }}>
                            <FontAwesomeIcon icon={hover ? faLockOpen : faLock} className={`${hover ? 'text-primary' : 'text-danger'} changeIcon`} size="2x" />
                        </div>
                    </div>
                }
                {!props.isInUse && !props.blocked &&
                    <div className="tile-label-overdue position-absolute me-2 mb-2 bottom-0 end-0 text-white fw-bold">
                        <div className="mx-2" onMouseOver={() => { setMouseHover(true) }} onMouseLeave={() => { setMouseHover(false) }} onClick={(event) => { props.changeLock(); event.stopPropagation() }}>
                            <FontAwesomeIcon icon={hover ? faLock : faLockOpen} className={`${hover ? 'text-danger' : 'text-primary'} changeIcon`} size="2x" />
                        </div>
                    </div>
                }
            </div>
        </Col>

    );
}

export default BoatTile;

import { useEffect, useState, useRef, useCallback } from "react";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckIn, PublicCheckIn } from "../../types";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import { getAllCurrentCheckIns } from "../../api/checkIn";
import { faArrowDown, faArrowUp, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import TableSearch from "../../components/TableSearch";
import TableFilter from "../../components/TableFilter";
import TableDateFilter from "../../components/TableDateFilter";

function BoatList() {
  const [checkIns, setcheckIns] = useState<PublicCheckIn[]>([]);
  const { t } = useTranslation();

  const [boatNameSearch, setBoatNameSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("date");
  const [isAsc, setIsAsc] = useState<boolean>(true);

  async function getcheckIns() {
    const checkIns = await getAllCurrentCheckIns();
    setcheckIns(checkIns);
  }

  function setSort(by: string) {
    if (sortBy !== by) {
      setSortBy(by);
      setIsAsc(false);
    } else {
      setIsAsc(!isAsc);
    }
  }

  useEffect(() => {
    getcheckIns();
  }, []);

  return (
    <Container className="pt-5">
      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <Modal>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <span onClick={() => { setSort("boat") }}>
                      {t("boatList.boatName")}
                      {sortBy === "boat" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{visibility: "hidden"}} /> }
                    </span>
                    <TableSearch state={boatNameSearch} onChange={(data) => { setBoatNameSearch(data) }} />
                  </th>
                  <th>
                    <span onClick={() => { setSort("startTime") }}>
                      {t("boatList.startTime")}
                      {sortBy === "startTime" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{visibility: "hidden"}} /> }
                    </span>
                  </th>
                  <th>
                    <span onClick={() => { setSort("estimatedEndTime") }}>
                      {t("boatList.endTime")}
                      {sortBy === "estimatedEndTime" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{visibility: "hidden"}} /> }
                    </span>
                  </th>
                  <th>
                    <span onClick={() => { }}>
                      {t("boatList.returnBoat")}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {checkIns
                  .filter((checkIn) => checkIn.Boat.name.toLocaleLowerCase().includes(boatNameSearch.toLocaleLowerCase()))
                  .sort((a, b) => {
                    if (sortBy === "date") {
                      return (Date.parse(b.date) - Date.parse(a.date)) * (isAsc ? 1 : -1);
                    }
                    else if (sortBy === "boat") {
                      return b.Boat.name.localeCompare(a.Boat.name) * (isAsc ? 1 : -1);
                    }
                    else {
                      return (b[sortBy as keyof PublicCheckIn]?.toString().localeCompare(a[sortBy as keyof PublicCheckIn]?.toString() || "") || 0) * (isAsc ? 1 : -1)
                    }
                  })
                  .map((x, i) => (
                    <tr key={x.id}>
                      <td>{x.Boat.name}</td>
                      <td>{(new Date(x.startTime))?.getHours().toString().padStart(2, "0") + ':' + (new Date(x.startTime))?.getMinutes().toString().padStart(2, "0")}</td>
                      <td>{(new Date(x.estimatedEndTime))?.getHours().toString().padStart(2, "0") + ':' + (new Date(x.estimatedEndTime))?.getMinutes().toString().padStart(2, "0")}</td>
                      <td><Link to={"./../checkout/" + x.id}><Button>{t("boatList.returnBoat")}</Button></Link></td>
                    </tr>
                  )
                  )
                }
              </tbody>
            </Table>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default BoatList;

import { useEffect, useState, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowUp, faPlus, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StaffModal from "../../components/StaffModal";
import { deleteSportInApi, getSportsFromApi, addSport, editSport } from "../../api/sport";
import { Form } from "react-bootstrap";
import { Controller, useForm, ValidateResult } from "react-hook-form";
import { Sport } from "../../types";
import { NavLink } from "react-router-dom";
import TableSearch from "../../components/TableSearch";

function Sports() {
  let mounted = useRef(true);
  const { t } = useTranslation();
  const [editElement, setEditElement] = useState<Sport | undefined>(undefined);
  const [deleteElement, setDeleteElement] = useState<number>(-1);
  const [isAdding, setAdding] = useState<boolean>(false);
  const [sports, setSports] = useState<Sport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sportNameSearch, setSportNameSearch] = useState<string>("");

  const [sortBy, setSortBy] = useState<string>("name");
  const [isAsc, setIsAsc] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<Sport>({
    mode: "onChange",
    reValidateMode: 'onChange'
  });

  function setSort(by: string) {
    if (sortBy !== by) {
      setSortBy(by);
      setIsAsc(false);
    } else {
      setIsAsc(!isAsc);
    }
  }

  async function getSports() {
    const sports = await getSportsFromApi();
    if (mounted) {
      setSports(sports);
    }
  }

  useEffect(() => {
    getSports();
    return () => {
      mounted.current = false;
    }
  }, []);

  return (
    <div className="m-1 h-100">
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>
              <div className="header">
                <span onClick={() => { setSort("name") }}>
                  {t("sports.Name")}
                  {sortBy === "name" ? <FontAwesomeIcon icon={isAsc ? faArrowUp : faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} style={{visibility: "hidden"}} /> }
                </span>
                <TableSearch state={sportNameSearch} onChange={(data) => { setSportNameSearch(data) }} />
              </div>
            </th>
            <th>
              {t("sports.Color")}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sports.sort((a, b) => {
            return (b[sortBy as keyof Sport]?.localeCompare(a[sortBy as keyof Sport] || "") || 0) * (isAsc ? 1 : -1)
          })
            .filter((sport) => sport.name.toLocaleLowerCase().includes(sportNameSearch.toLocaleLowerCase()))
            .map((x) => (
              <tr key={x.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  reset();
                  setEditElement(x);
                  setValue('id', x.id);
                  setValue('name', x.name);
                  setValue('color', x.color);
                }}>
                <td>
                  {x.name}
                </td>
                <td>
                  <div className="d-flex" style={{ "alignItems": "center" }}>
                    <div style={{ "borderRadius": "50%", "height": "20px", "width": "20px", "marginRight": "10px", "background": x.color }}></div>
                    {x.color}
                  </div>
                </td>
                <td>
                  <div className="d-flex">
                    <NavLink to={"/staff/statistics/sport/" + x.id}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="text-secondary clickableIcon"
                        size="1x" />
                    </NavLink>
                    <div
                      className="mx-2"
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeleteElement(sports.indexOf(x));
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="text-danger clickableIcon"
                        size="1x"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        className="d-flex px-2 py-1 justify-content-end bg-white border-top"
        style={{ position: "sticky", right: "5px", bottom: "0", zIndex: 3 }}
      >
        <Button
          onClick={() => {
            reset();
            setAdding(true);
            setEditElement({ id: "", name: "", color: "#003366" } as Sport);
            setValue('id', undefined as any);
            setValue('name', "");
            setValue('color', "#003366");
          }}
          variant="secondary"
        >
          <FontAwesomeIcon icon={faPlus} className="text-white me-2" />
          {t("sports.AddSport")}
        </Button>
      </div>
      <StaffModal
        header={isAdding ? t("sports.AddSport") : t("sports.EditSport")}
        show={!!editElement}
        successText={isAdding ? t("common.Add") : t("common.Edit")}
        loadingNext={loading}
        disableNext={!isValid}
        onHide={() => {
          setEditElement(undefined);
          setAdding(false);
        }}
        onSuccess={() => {
          setLoading(true);
          handleSubmit((data) => {
            if (isAdding) {
              addSport(data).then(async () => {
                await getSports();
                setEditElement(undefined);
                setAdding(false);
                setLoading(false);
              });
            } else {
              editSport(data).then(async () => {
                await getSports();
                setEditElement(undefined);
                setAdding(false);
                setLoading(false);
              });
            }
          })()
        }}
      >
        <Form>
          <Controller
            name="name"
            control={control}
            defaultValue={editElement?.name}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("sports.Name")}</Form.Label>
                <Form.Control 
                  type="text" 
                  {...field} 
                  isInvalid={!!errors.name} 
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t("common.messages.required", {
                  val: t("sports.Name"),
                }),
              },
              validate: (value: string): ValidateResult => {
                const isRedundant = sports.some((sport) => sport.name.trim() === value.trim() && sport.id !== editElement?.id );
                if ( isRedundant ) {
                  return t("common.messages.nameExists") as ValidateResult;
                }
                return undefined
              },              
            }}
          />
          <Controller
            name="color"
            control={control}
            defaultValue={editElement?.color}
            render={({ field }) => (
              <div className="mb-2">
                <Form.Label>{t("sports.Color")}</Form.Label>
                <Form.Control type="color" {...field} isInvalid={!!errors.color} />
                <Form.Control.Feedback type="invalid">
                  {errors.color?.message}
                </Form.Control.Feedback>
              </div>
            )}
          />
        </Form>

      </StaffModal>
      <StaffModal
        header={t("sports.DeleteSport")}
        hideColor="secondary"
        successText={t("common.Delete")}
        successColor="danger"
        show={deleteElement !== -1}
        loadingNext={loading}
        onHide={() => {
          setDeleteElement(-1);
        }}
        onSuccess={async () => {
          setLoading(true);
          await deleteSportInApi({ id: sports[deleteElement].id })
          await getSports();
          setDeleteElement(-1);
          setLoading(false);
        }}
      >
        <span>
          {t("sports.messages.DeleteText", { val: sports[deleteElement]?.name })}
        </span>
      </StaffModal>
    </div>
  );
}

export default Sports;

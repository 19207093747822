import { Accessory, ID } from "../types"

export async function getAccessoriesFromApi() {
  const response = await fetch("/api/accessory", {
    method: "GET",
  });
  const json = await response.json();
  return json;
}

export async function addAccessory({
  name,
  accessorytype,
  status,
}: Omit<Accessory, "id">) {
  const response = await fetch("/api/accessory", {
    method: "POST",
    body: JSON.stringify({
      name: name,
      accessorytype: accessorytype,
      status: status || 0,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function editAccessory({
  id,
  name,
  accessorytype,
  status,
}: Accessory) {
  const response = await fetch(`/api/accessory/${id}`, {
    method: "PATCH",
    body: JSON.stringify({
      name,
      accessorytype,
      status: status || 0,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function deleteAccessory({ id }: ID) {
  const response = await fetch(`/api/accessory/${id}`, {
    method: "DELETE",
  });
  const json = await response.json();
  return json;
}

export async function lockAccessory({ id }: ID) {
  const response = await fetch(`/api/accessory/lock/${id}`, {
    method: "POST",
  });
  const json = await response.json();
  return json;
}

export async function unlockAccessory({ id }: ID) {
  const response = await fetch(`/api/accessory/unlock/${id}`, {
    method: "POST",
  });
  const json = await response.json();
  return json;
}

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo_unisport.png";
import Divider from "./layout/Divider";

import { serverurl } from "../components/serverurl";

function Modal(props: any) {
  /** Modal: The Base Container in the Userfrontend
   *  Gives us the UniSport-Image and the Close-Button
   * 
   *  Since the Close-Button needs to return to the base page, which either is `/` or `/terminal/`, it needs to be aware on which version it is right now.
   *  This is done with the useLocation()-Hook, which gives us the current URL. If "terminal" is part of that URL, it is assumed that we need to return to the terminal.
   */


  let location = useLocation().pathname;

  let isTerminal = false;
  if (location.includes("terminal")) {
    isTerminal = true;
  }

  return (
    <div className="my-3 p-3 bg-white bg-opacity-100 rounded-3">
      <div className="text-end">
        <Link to={isTerminal ? "/terminal/" : "/"}>
          <FontAwesomeIcon icon={faTimesCircle} color="rgba(0, 0, 0, 0.3)" />
        </Link>
      </div>
      {props.children}
      <div className="text-center mt-3">
        <Divider />
        <Image src={serverurl + "static-instance/logo.png"} className="w-25" />
      </div>
    </div>
  );
}
export default Modal;

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";


function TableDateFilter(props: { stateFrom: string, stateTo: string, onChangeFrom: (val: string) => void, onChangeTo: (val: string) => void }) {
    const [isOpen, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <div className={"tableSearch " + (props.stateFrom || props.stateTo || isOpen ? "active" : "")}>
            <FontAwesomeIcon onClick={(event) => { setOpen(!isOpen); event.stopPropagation(); }} icon={faSearch} className={"searchIcon"} inverse={!!props.stateFrom || !!props.stateTo || isOpen}></FontAwesomeIcon>
            {isOpen &&
                <div className="popover">
                    <Form.Label>{t("statistics.From")}</Form.Label>
                    <Form.Control
                        type="date"
                        className="form-control-sm"
                        placeholder="Search"
                        aria-label="Search"
                        defaultValue={props.stateFrom}
                        onChange={(event) => {
                            props.onChangeFrom(event.target.value);
                        }}
                    />
                    <Form.Label>{t("statistics.To")}</Form.Label>
                    <Form.Control
                        type="date"
                        className="form-control-sm"
                        defaultValue={props.stateTo}
                        onChange={(event) => {
                            props.onChangeTo(event.target.value);
                        }}
                    />
                </div>
            }
        </div>
    );
}

export default TableDateFilter;

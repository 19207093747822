import { useState } from "react";
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { staffLogin } from "../../api/auth";
import Divider from "../../components/layout/Divider";
import Modal from "../../components/Modal";

type FormData = {
  email: string;
  password: string;
};

function Login(props: {onChange: ()=>Promise<void>}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: 'onChange'
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showLoginError, setShowLoginError] = useState(false);

  const onSubmit = async (data: FormData) => {
    try {
      const response = await staffLogin(data);
      if (response.success) {
        await props.onChange();
        navigate("/staff", { replace: true });
      }
    } catch (e) {
      setShowLoginError(true)
    }
  };

  return (
    <Container className="position-absolute top-50 start-50 translate-middle">
      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          {showLoginError && <Alert variant="danger" onClose={() => setShowLoginError(false)} dismissible>
            <Alert.Heading>{t("staffLogin.LoginError")}</Alert.Heading>
            <p>
              {t("staffLogin.LoginErrorText")}
            </p>
          </Alert>}
          <Modal>
            <h1 className="text-center p-1">{t("staffLogin.title")}</h1>
            <p className="text-center">{t("staffLogin.subtitle")}</p>
            <Divider />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="mb-2">
                    <Form.Label>{t("staffLogin.labelEmail")}</Form.Label>
                    <Form.Control
                      type="email"
                      {...field}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("common.messages.required", {
                      val: t("staffLogin.labelEmail"),
                    }),
                  },
                  validate: (value: string) =>
                    validator.isEmail(value) ||
                    t("staffLogin.messages.invalidEmail").toString(),
                }}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="mb-2">
                    <Form.Label>{t("staffLogin.labelPassword")}</Form.Label>
                    <Form.Control
                      type="password"
                      {...field}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password?.message}
                    </Form.Control.Feedback>
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("common.messages.required", {
                      val: t("staffLogin.labelPassword"),
                    }),
                  },
                }}
              />
              <Button type="submit" variant="secondary" className="mt-2 w-100">
                {t("staffLogin.buttonSignIn")}
              </Button>
            </Form>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;

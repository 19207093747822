import { useTranslation } from "react-i18next";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import { Helmet } from "react-helmet-async";
import { ResponsiveCalendar } from "@nivo/calendar";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";

function StatisticOverview() {
  let mounted = useRef(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [calendardata, setCalendarData] = useState<any>([]);
  const [kpiData, setKpiData] = useState<{
    checkins: number;
    notes: number;
    openNotes: number;
  }>({ checkins: 0, notes: 0, openNotes: 0 });
  const [barDataSport, setBarDataSport] = useState<any>([]);
  const [piedataSport, setPieDataSport] = useState<any>([]);

  const [barDataBoatType, setBarDataBoatType] = useState<any>([]);
  const [piedataBoatType, setPieDataBoatType] = useState<any>([]);

  const [barDataBoat, setBarDataBoat] = useState<any>([]);
  const [piedataBoat, setPieDataBoat] = useState<any>([]);

  const [uniqueIds, setUniqueIds] = useState<any>([]);

  function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          R: parseInt(result[1], 16),
          G: parseInt(result[2], 16),
          B: parseInt(result[3], 16),
        }
      : null;
  }
  function isDark(col: { R: number; G: number; B: number }) {
    let luma = (0.299 * col.R + 0.587 * col.G + 0.114 * col.B) / 255;
    return luma < 0.35;
  }

  function getCurrentYear() {
    return new Date().getFullYear();
  }

  async function getData() {
    const response = await fetch("/api/statistic/", {
      method: "GET",
    });
    const json = await response.json();
    const d: {
      checkins: {
        sportId: string;
        sportName: string;
        sportColor: string;
        boatId: string;
        boatName: string;
        boatTypeId: string;
        boatTypeName: string;
        date: string;
        hasNote: boolean;
        noteIsOpen: boolean;
        additionalClients: { passenger: string }[];
      }[];
    } = json.result;

    // Get amount of Checkins per Day
    const uniqueDates = d.checkins
      ? [...(new Set(d.checkins.map((item) => item.date)) as any)]
      : [];
    let dataCal: any[] = [];
    for (let u of uniqueDates) {
      dataCal.push({
        day: u,
        value: d.checkins.filter((x) => x.date === u).length,
      });
    }

    // Get amount of passengers and total checkin count for every boat
    const uniqueIdsBoats = d.checkins
      ? [...(new Set(d.checkins.map((item) => item.boatId)) as any)]
      : [];
    let dataPieBoats: any[] = [];
    for (let u of uniqueIdsBoats) {

      let checkins_with_boat_list = d.checkins.filter((x) => x.boatId === u);
      if (checkins_with_boat_list.length === 0) {
        continue
      }

      let checkin_with_boat = checkins_with_boat_list[0];

      let total_passenger_amount = 0;
      for (let checkin of checkins_with_boat_list) {
        total_passenger_amount += (checkin.additionalClients.length) + 1
      }

      dataPieBoats.push({
        id: checkin_with_boat.boatName,
        label: checkin_with_boat.boatName,
        average_passenger_count: parseFloat((total_passenger_amount / checkins_with_boat_list.length).toFixed(2)),
        value: checkins_with_boat_list.length,
      });
    }

    // Get total checkin count for every boat type
    const uniqueIdsBoatTypes = d.checkins
      ? [...(new Set(d.checkins.map((item) => item.boatTypeId)) as any)]
      : [];
    let dataPieBoatTypes: any[] = [];

    for (let u of uniqueIdsBoatTypes) {
      let checkins_with_boattype_list = d.checkins.filter((x) => x.boatTypeId === u);
      if (checkins_with_boattype_list.length === 0) {
        continue
      }
      let checkin_with_boattype = checkins_with_boattype_list[0];

      dataPieBoatTypes.push({
        id: checkin_with_boattype.boatTypeName,
        uuid: checkin_with_boattype.boatTypeId,
        label: checkin_with_boattype.boatTypeName,
        value: checkins_with_boattype_list.length,
      });
    }

    // Get total checkin count for every sport
    const uniqueIdsSports = d.checkins
      ? [...(new Set(d.checkins.map((item) => item.sportId)) as any)]
      : [];
    let dataPieSports: any[] = [];
    for (let u of uniqueIdsSports) {
      let checkins_with_sport_list = d.checkins.filter((x) => x.sportId === u);
      if (checkins_with_sport_list.length === 0) {
        continue
      }
      let checkin_with_sport = checkins_with_sport_list[0];

      dataPieSports.push({
        id: checkin_with_sport.sportName,
        uuid: checkin_with_sport.sportId,
        label: checkin_with_sport.sportName,
        value: checkins_with_sport_list.length,
        color: checkin_with_sport.sportColor || "#003366",
      });
    }

    const uniqueNamesSports = d.checkins
      ? [...(new Set(d.checkins.map((item) => item.sportName)) as any)]
      : [];
    let dataBarSports: any[] = [1, 2, 3, 4, 5, 6, 0].map((u) => {
      let obj: any = {
        weekday: t("statistics.weekdays", { returnObjects: true })[u],
      };
      for (let s of uniqueIdsSports) {
        let id = d.checkins.find((x) => x.sportId === s)?.sportName || "";
        obj[id] = d.checkins
          .filter((x) => x.sportId === s)
          .filter((x) => new Date(x.date).getDay() === u).length;
        obj[id + "Color"] =
          d.checkins.find((x) => x.sportId === s)?.sportColor || "#003366";
        obj[id + "Uuid"] = s;
      }
      return obj;
    });

    const uniqueNamesBoatType = d.checkins
      ? [...(new Set(d.checkins.map((item) => item.boatTypeName)) as any)]
      : [];
    let dataBarBoatTypes: any[] = [1, 2, 3, 4, 5, 6, 0].map((u) => {
      let obj: any = {
        weekday: t("statistics.weekdays", { returnObjects: true })[u],
      };
      for (let s of uniqueIdsBoatTypes) {
        let id = d.checkins.find((x) => x.boatTypeId === s)?.boatTypeName || "";
        obj[id] = d.checkins
          .filter((x) => x.boatTypeId === s)
          .filter((x) => new Date(x.date).getDay() === u).length;
        obj[id + "Uuid"] = s;
      }
      return obj;
    });

    const uniqueNamesBoat = d.checkins
      ? [...(new Set(d.checkins.map((item) => item.boatName)) as any)]
      : [];
    let dataBarBoats: any[] = [1, 2, 3, 4, 5, 6, 0].map((u) => {
      let obj: any = {
        weekday: t("statistics.weekdays", { returnObjects: true })[u],
      };
      for (let s of uniqueIdsBoats) {
        let id = d.checkins.find((x) => x.boatId === s)?.boatName || "";
        obj[id] = d.checkins
          .filter((x) => x.boatId === s)
          .filter((x) => new Date(x.date).getDay() === u).length;
      }
      return obj;
    });

    if (mounted) {
      setCalendarData(dataCal);
      setBarDataBoat(dataBarBoats);
      setBarDataBoatType(dataBarBoatTypes);
      setBarDataSport(dataBarSports);

      setUniqueIds({
        sports: uniqueNamesSports,
        boattypes: uniqueNamesBoatType,
        boats: uniqueNamesBoat,
      });

      setPieDataBoat(dataPieBoats);
      setPieDataBoatType(dataPieBoatTypes);
      setPieDataSport(dataPieSports);
      setKpiData({
        checkins: d.checkins.length,
        notes: d.checkins.filter((x) => x.hasNote).length,
        openNotes: d.checkins.filter((x) => x.noteIsOpen).length,
      });
    }
  }
  useEffect(() => {
    getData();
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "calc(100vh - 58px)" }}>
      <Helmet>
        <title>{t("routes./staff/statistics")}</title>
      </Helmet>
      <h1 className="text-center" style={{ width: "50%" }}>
        {t("routes./staff/statistics")}
      </h1>
      <div
        className="d-flex"
        style={{
          flexFlow: "row wrap",
          width: "100%",
          height: "calc(100% - 58px)",
        }}
      >
        <div className="p-5" style={{ width: "50%", height: "50%" }}>
          <div className="kpi">
            <b>{t("statistics.Checkins")}:</b> <span>{kpiData.checkins}</span>
          </div>
          <div className="kpi">
            <b>{t("statistics.Notes")}:</b>
            <span>{kpiData.notes}</span>
          </div>
          <div className="kpi">
            <b>{t("statistics.openNotes")}:</b> <span>{kpiData.openNotes}</span>
          </div>
        </div>
        <div className="calendar" style={{ width: "50%", height: "50%" }}>
          <ResponsiveCalendar
            data={calendardata}
            from={getCurrentYear() - 1 + "-01-01"}
            to={getCurrentYear() + "-12-31"}
            emptyColor="#eeeeee"
            colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            legends={[
              {
                anchor: "bottom-right",
                direction: "row",
                translateY: 36,
                itemCount: 4,
                itemWidth: 42,
                itemHeight: 36,
                itemsSpacing: 14,
                itemDirection: "right-to-left",
              },
            ]}
          />
        </div>
        <div className="piechart" style={{ width: "50%", height: "50%" }}>
          <h3 className="mx-3">{t("sports.Name")}</h3>
          <ResponsivePie
            onClick={(n: any) => {
              navigate(`/staff/statistics/sport/${n.data.uuid}`);
            }}
            data={piedataSport}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            borderColor={{
              from: "color",
              modifiers: [
                ["darker", 0.6],
                ["opacity", 0.5],
              ],
            }}
            activeOuterRadiusOffset={8}
            colors={(d: any) => d.data.color}
            borderWidth={1}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLabelsTextColor={(d: any) => {
              return isDark(hexToRgb(d.data.color) || { R: 0, G: 0, B: 0 })
                ? "#FFFFFF"
                : "#000000";
            }}
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
            arcLabel="value"
            arcLinkLabel="label"
          />
        </div>
        <div className="barchart" style={{ width: "50%", height: "50%" }}>
          <ResponsiveBar
            onClick={(n: any) => {
              navigate("/staff/statistics/sport/" + n.data[`${n.id}Uuid`]);
            }}
            data={barDataSport}
            keys={uniqueIds.sports}
            indexBy="weekday"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={({ id, data }) => String(data[`${id}Color`])}
            labelTextColor={(d: any) => {
              return isDark(hexToRgb(d.data.color) || { R: 0, G: 0, B: 0 })
                ? "#FFFFFF"
                : "#000000";
            }}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="piechart" style={{ width: "50%", height: "50%" }}>
          <h3 className="mx-3">{t("boatManager.BoatType")}</h3>

          <ResponsivePie
            onClick={(n: any) => {
              navigate(`/staff/statistics/boattype/${n.data.uuid}`);
            }}
            data={piedataBoatType}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            borderColor={{
              from: "color",
              modifiers: [
                ["darker", 0.6],
                ["opacity", 0.5],
              ],
            }}
            activeOuterRadiusOffset={8}
            colors={{ scheme: "paired" }}
            borderWidth={1}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
            arcLabel="value"
            arcLinkLabel="label"
          />
        </div>
        <div className="barchart" style={{ width: "50%", height: "50%" }}>
          <ResponsiveBar
            onClick={(n: any) => {
              navigate("/staff/statistics/boattype/" + n.data[`${n.id}Uuid`]);
            }}
            data={barDataBoatType}
            keys={uniqueIds.boattypes}
            indexBy="weekday"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "paired" }}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="piechart" style={{ width: "50%", height: "50%" }}>
          <h3 className="mx-3">{t("statistics.Boat")}</h3>
          <ResponsivePie
            data={piedataBoat}
            tooltip={(props) => {
              let data: any = props.datum.data;
              return (
                <div style={{fontSize: "13px", backgroundColor: "#ffffff", padding:"5px", border: "1px solid #dfdfdf", }}>
                  <span>{t("boatstatistic.Boatname")}: {data.label}</span> <br />
                  <span>{t("boatstatistic.NumberOfBookings")}: {data.value}</span> <br />
                  <span>{t("boatstatistic.AveragePassengerCount")}: {data.average_passenger_count}</span>
                </div>
              )
            }}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            borderColor={{
              from: "color",
              modifiers: [
                ["darker", 0.6],
                ["opacity", 0.5],
              ],
            }}
            activeOuterRadiusOffset={8}
            colors={{ scheme: "paired" }}
            borderWidth={1}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
            arcLabel="value"
            arcLinkLabel="label"
          />
        </div>
        <div className="barchart" style={{ width: "50%", height: "50%" }}>
          <ResponsiveBar
            data={barDataBoat}
            keys={uniqueIds.boats}
            indexBy="weekday"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "paired" }}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default StatisticOverview;

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { Helmet } from "react-helmet-async";
import { ResponsiveCalendar } from "@nivo/calendar";
import { useState, useEffect, useRef } from "react";

function SingleStatisticSport() {
  let mounted = useRef(true);
  const { t } = useTranslation();
  const { id } = useParams();
  const [piedata, setPieData] = useState<any>([]);
  const [calendardata, setCalendarData] = useState<any>([]);
  const [sportName, setSportName] = useState<string>("");
  const [kpiData, setKpiData] = useState<{
    checkins: number;
    notes: number;
    openNotes: number;
  }>({ checkins: 0, notes: 0, openNotes: 0 });
  const [lineData, setLineData] = useState<any>([]);

  async function getData() {
    const response = await fetch("/api/statistic/sport/" + id, {
      method: "GET",
    });
    const json = await response.json();
    const d: {
      sportName: string;
      checkins: {
        boatId: string;
        boatName: string;
        boatTypeId: string;
        boatTypeName: string;
        date: string;
        hasNote: boolean;
        noteIsOpen: boolean;
      }[];
    } = json.result;

    const uniqueIds = d.checkins
      ? [...(new Set(d.checkins.map((item) => item.boatId)) as any)]
      : [];
    let dataPie: any[] = [];
    for (let u of uniqueIds) {
      dataPie.push({
        id: d.checkins.find((x) => x.boatId === u)?.boatName,
        label: d.checkins.find((x) => x.boatId === u)?.boatName,
        value: d.checkins.filter((x) => x.boatId === u).length,
      });
    }

    const uniqueDates = d.checkins
      ? [...(new Set(d.checkins.map((item) => item.date)) as any)]
      : [];
    let dataCal: any[] = [];
    for (let u of uniqueDates) {
      dataCal.push({
        day: u,
        value: d.checkins.filter((x) => x.date === u).length,
      });
    }

    let dataLine: any[] = [0, 1, 2, 3, 4, 5, 6].map((u) => {
      return {
        x: t("statistics.weekdays", { returnObjects: true })[u],
        y: d.checkins.filter((x) => new Date(x.date).getDay() === u).length,
      };
    });

    if (mounted) {
      setPieData(dataPie);
      setCalendarData(dataCal);
      setSportName(d.sportName);
      setLineData(dataLine);
      setKpiData({
        checkins: d.checkins.length,
        notes: d.checkins.filter((x) => x.hasNote).length,
        openNotes: d.checkins.filter((x) => x.noteIsOpen).length,
      });
    }
  }
  useEffect(() => {
    getData();
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "calc(100vh - 58px)" }}>
      <Helmet>
        <title>{t("sportstatistic.title")}</title>
      </Helmet>
      <h1 className="text-center" style={{ width: "50%" }}>
        {sportName}
      </h1>
      {piedata.length > 0 && (
        <div
          className="d-flex"
          style={{
            flexFlow: "row wrap",
            width: "100%",
            height: "calc(100% - 58px)",
          }}
        >
          <div className="p-5" style={{ width: "50%", height: "50%" }}>
            <div className="kpi">
              <b>{t("statistics.Checkins")}:</b> <span>{kpiData.checkins}</span>
            </div>
            <div className="kpi">
              <b>{t("statistics.Notes")}:</b>
              <span>{kpiData.notes}</span>
            </div>
            <div className="kpi">
              <b>{t("statistics.openNotes")}:</b> <span>{kpiData.openNotes}</span>
            </div>
          </div>
          <div className="calendar" style={{ width: "50%", height: "50%" }}>
            <ResponsiveCalendar
              data={calendardata}
              from="2021-01-01"
              to="2022-12-31"
              emptyColor="#eeeeee"
              colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              yearSpacing={40}
              monthBorderColor="#ffffff"
              dayBorderWidth={2}
              dayBorderColor="#ffffff"
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "row",
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: "right-to-left",
                },
              ]}
            />
          </div>
          <div className="piechart" style={{ width: "50%", height: "50%" }}>
            <ResponsivePie
              data={piedata}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              borderColor={{
                from: "color",
                modifiers: [
                  ["darker", 0.6],
                  ["opacity", 0.5],
                ],
              }}
              activeOuterRadiusOffset={8}
              colors={{ scheme: "paired" }}
              borderWidth={1}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLabelsSkipAngle={10}
              arcLabel="value"
              arcLinkLabel="label"
            />
          </div>
          <div className="linechart" style={{ width: "50%", height: "50%" }}>
            <ResponsiveLine
              data={[{
                    id: "line",
                    color: "rgb(166, 206, 227)",
                    data: lineData,
                  }]}
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
              }}
              colors={{ scheme: "paired" }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: t("statistics.count"),
                legendOffset: -40,
                legendPosition: "middle",
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
            />
          </div>
        </div>
      )}
      {piedata.length === 0 && (
        <div
          className="d-flex w-100 h-100"
          style={{
            placeContent: "center",
            placeItems: "center",
          }}
        >
          <span>{t("sportstatistic.noDataAvailible")}</span>
        </div>
      )}
    </div>
  );
}

export default SingleStatisticSport;

import { Button, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Roles } from "../../components/roles";

import { serverurl } from "../../components/serverurl";

function Home(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function logout() {
    try {
      await fetch("/api/logout", {
        method: "POST",
      });
      navigate(0); // Do not navigate, but reload the page to update DOM tree
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div style={{ height: "100%", width: "100%", position: "absolute", textAlign: "center" }}>
      <div className="vertical-center" style={{ display: "grid" }}>
        <Image src={serverurl + "static-instance/logo.png"} fluid className="mb-5 mx-auto" style={{ maxWidth: "50vh" }} />

        {[Roles.boatManager, Roles.coordinator].includes(props.role) ? (
          <div className="alert alert-danger" style={{ marginLeft: "15%", marginRight: "15%" }}>
            <div>
              {t("home.adminLoginAlert")}
            </div>

            <button type="button" className="btn btn-light" style={{ marginTop: "5%" }} onClick={() => { logout() }}>{t("home.adminLogoutButton")}</button>
          </div>
        ) : (
          <></>
        )

        }
        <div style={{ marginTop: "0%" }}>

          <Link to="checkin">
            <Button
              variant="primary"
              className="w-100 mb-2 text-uppercase text-secondary border btn-custom mx-auto"
            >
              {t("home.buttonBookBoat")}
            </Button>
          </Link>

          {props.role === Roles.terminalUser ?
            (<Link to="/terminal/return">
              <Button
                variant="primary"
                className="w-100 mb-2 text-uppercase text-secondary border btn-custom mx-auto"
              >
                {t("home.buttonReturnBoat")}
              </Button>
            </Link>)
            :
            (<></>)
          }

          {/* A relative link, to allow linking to either `login` or `terminal/login`, both being the same sites. */}
          <Link to="./login">
            <Button
              variant="secondary"
              className="w-100 mb-2 text-uppercase border btn-custom mx-auto"
            >
              {t("home.buttonStaffLogin")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;

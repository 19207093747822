import { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Divider from "../../components/layout/Divider";
import Modal from "../../components/Modal";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSportsFromApi } from "../../api/sport";
import { getBoatsOverview } from "../../api/boat";
import { checkIn, getAgb } from "../../api/checkIn";
import { Sport, OverviewBoat, CheckIn, Accessory } from "../../types";
import { getAccessoriesFromApi } from "../../api/accessory";

function Book() {
  let mounted = useRef(true);
  const [sports, setSports] = useState<Sport[]>([]);
  const [boats, setBoats] = useState<OverviewBoat[]>([]);
  const [accessories, setAccessories] = useState<Accessory[]>([]);
  const [associatedAccessories, setAssociatedAccessories] = useState<Accessory[]>([]);
  const [agb, setAgb] = useState<any>({});

  async function getSports() {
    let sports = await getSportsFromApi();
    if (mounted) {
      setSports(sports);
    }
  }

  async function getBoats() {
    const boats = ((await getBoatsOverview()).filter(x => x.status === 0).sort((a, b) => a.name.localeCompare(b.name)));
    if (mounted) {
      setBoats(boats);
    }
  }

  async function getAccessories() {
    let accessories = await getAccessoriesFromApi();
    if (mounted) {
      // Explicitly don't filter the accessories, because we need to be able to differentiate between boat types which just don't have accessories,
      // and boat types where all accessories are booked
      setAccessories(accessories.result.sort((a: Accessory, b: Accessory) => a.name.localeCompare(b.name)));
    }
  }

  async function getAgbJson() {
    let agbJson = await getAgb();
    if (mounted) {
      setAgb(agbJson);
    }
  }

  const getFreshData = () => {
    getSports();
    getBoats();
    getAccessories();
    getAgbJson();
  }

  useEffect(() => {
    getFreshData();
    return () => {
      mounted.current = false;
    }
  }, []);

  function addMinutes(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes * 60000);
  }

  function getEndTime(startTime: string, add: number, bonus?: boolean) {
    let date = new Date();
    date.setHours(
      Number(startTime.split(":")[0]),
      Number(startTime.split(":")[1]),
      0,
      0
    );
    date = addMinutes(date, Number(((add - Math.floor(add || 0)) * 60).toFixed(0)) + (bonus ? 5 : 0))
    let h = Math.floor(add || 0);
    return zeroPad((date.getHours() + h) % 24, 2) + ":" + zeroPad(date.getMinutes(), 2)
  }

  let date = new Date();
  const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, "0");
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<CheckIn>({
    mode: "onChange",
    reValidateMode: 'onChange',
    defaultValues: {
      sport: "",
      boatName: "",
      accessory: "",
      startTime:
        zeroPad(date.getHours(), 2) + ":" + zeroPad(date.getMinutes(), 2),
      estimatedEndTime:
        zeroPad((date.getHours() + 2) % 24, 2) + ":" + zeroPad(date.getMinutes(), 2),
      destination: "",
      fullNameOfResponsibleClient: "",
      additionalClients: [],
      termsAndConditions: false,
      isCourse: false,
    },
  });
  const {
    fields: additionalClients,
    append: appendPerson,
    remove: removePerson,
  } = useFieldArray({
    control,
    name: "additionalClients",
  });
  const { t, i18n } = useTranslation(); // i18n



  const onSubmit = (data: CheckIn) => {
    let s = async () => {
      try {
        let res = await checkIn(data);
        console.log(res)

        if (res.success) {
          // A relative Navigation, allowing both `checkin/success` and `terminal/checkin/success` to be navigated to.
          navigate("./success");
        } else {
          alert("Something went wrong! Please try again.\n" + res.error)
          getFreshData();
        }

      } catch (e) {
        alert("error");
      }
    }
    s()
  };

  const refreshBoats = () => {
    // If a boat is chosen, and the sport is changed, the boat is not displayed in the Dropdown anymore, but still is written in watch("boatName").
    // This allows the Checkin to be submitted without a visible boat selected by the user.
    // So, we need to actually clean out the Boat field if the sport is changed to a sport which does not include the boat.

    let currentBoat = boats.find(x => x.id === watch("boatName"));
    if (!currentBoat?.boattype?.Sports.some(x => x.id === watch("sport"))) {
      // Current boat does not have newly selected sport, so clean out boat selection
      setValue("boatName", "");
      setValue("accessory", "");
    }
  }

  const refreshAccessoryList = () => {
    let curboat = boats.filter((boat) => boat.id === watch("boatName"))[0];
    let curboattype = curboat?.boattype?.id;
    let accessorieswithboattype = accessories.filter((acc: any) => {
      let hasBoatType = acc.accessorytypedata?.BoatTypes.filter((boattype: any) => boattype.id === curboattype)
      return hasBoatType.length > 0
    })
    setAssociatedAccessories(accessorieswithboattype);
  }

  return (
    <Container className="pt-5">
      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <Modal>
            <h1 className="text-center">{t("bookingForm.title")}</h1>
            <p className="text-center">{t("bookingForm.subtitle")}</p>
            <Divider />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <Controller
                    name="sport"
                    control={control}
                    render={({ field }) => (
                      <div className="mb-2 required">
                        <Form.Label>{t("bookingForm.labelSport")}</Form.Label>
                        <Form.Select aria-label="Default select example" {...field} onChange={(a) => { field.onChange(a); refreshBoats(); refreshAccessoryList(); }}>
                          <option></option>
                          {sports.map((x) => (
                            <option key={x.id} value={x.id}>{x.name}</option>
                          ))}
                        </Form.Select>
                      </div>
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: t("common.messages.required", {
                          val: t("bookingForm.labelSport"),
                        }),
                      },
                    }}
                  />
                </Col>
                <Col>
                  <Controller
                    name="boatName"
                    control={control}
                    render={({ field }) => (
                      <div className="mb-2 required">
                        <Form.Label>{t("bookingForm.labelBoatName")}</Form.Label>
                        <Form.Select aria-label="Default select example"  {...field} onChange={(a) => {
                          field.onChange(a);
                          let numericEndTime = boats.find(x => x.id === watch("boatName"))?.boattype?.maxTime || 2;
                          let checkEndTime = getEndTime(watch("startTime"), (numericEndTime) + 0.5);

                          let generatedEndTime = getEndTime(watch("startTime"), (numericEndTime));

                          if (checkEndTime.localeCompare(getEndTime(watch("startTime"), 0)) !== 1) {
                            generatedEndTime = "23:30";
                          }

                          setValue("estimatedEndTime", generatedEndTime);

                          refreshAccessoryList();
                        }}>
                          <option></option>
                          {boats.filter(x => x?.boattype?.Sports.some(x => x.id === watch("sport"))).map((x) => (
                            <option key={x.id} value={x.id}>
                              {x.name} ({t("bookingForm.max")} {x.boattype?.seats} {t("bookingForm.person")})
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: t("common.messages.required", {
                          val: t("bookingForm.labelBoatName"),
                        }),
                      },
                    }}
                  />
                  {
                    (associatedAccessories.length === 0) ?
                      <></>
                      :
                      <Controller
                        name="accessory"
                        control={control}
                        render={({ field }) => (
                          <div className="mb-2 required">
                            <Form.Label>{t("bookingForm.labelAccessory")}</Form.Label>
                            <Form.Select aria-label="Default select example"  {...field}>
                              <option></option>
                              {/* Filter rentable Accessoires _only_ in Dropdown to avoid all-rented-out accessoires from not appearing*/}
                              {associatedAccessories.filter((x: Accessory) => x.status === 0).map((x) => (
                                <option key={x.id} value={x.id}>
                                  {x.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: t("common.messages.required", {
                              val: t("bookingForm.labelAccessory"),
                            }),
                          },
                        }}
                      />
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  <Controller
                    name="startTime"
                    control={control}
                    render={({ field }) => (
                      <div className="mb-2 required">
                        <Form.Label>{t("bookingForm.labelStartTime")}</Form.Label>
                        <Form.Control type="time" {...field} />
                      </div>
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: t("common.messages.required", {
                          val: t("bookingForm.labelStartTime"),
                        }),
                      },
                      pattern: {
                        value: /(((0|1)\d)|(2[0-3])):[0-5]\d/,
                        message: t("bookingForm.messages.invalidTime", {
                          val: t("bookingForm.labelStartTime"),
                        }),
                      },
                    }}
                  />
                </Col>
                <Col>
                  <Controller
                    name="estimatedEndTime"
                    control={control}
                    render={({ field }) => (
                      <div className="mb-2 required">
                        <Form.Label>
                          {t("bookingForm.labelEstimatedEndTime")}
                        </Form.Label>
                        <Form.Control type="time" {...field} />
                      </div>
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: t("common.messages.required", {
                          val: t("bookingForm.labelEstimatedEndTime"),
                        }),
                      },
                      pattern: {
                        value: /(((0|1)\d)|(2[0-3])):[0-5]\d/,
                        message: t("bookingForm.messages.invalidTime", {
                          val: t("bookingForm.labelEstimatedEndTime"),
                        }),
                      },
                      validate: (value: string) => {
                        // If the boat has unlimited checkout time, set the maxTime to 24 hours.
                        // Since the boats may only be checked out on one day, not over midnight, this results in every boat
                        // with unlimited time being valid until 23:30
                        let maxTime = boats.find(x => x.id === watch("boatName"))?.boattype?.maxTime || 24;

                        let startTime = watch("startTime");
                        // Go from "15:30" to Number(15.5)
                        let startInHoursSinceMidnight = Number(startTime.split(":")[0]) + Number(startTime.split(":")[1]) / 60;

                        if (startInHoursSinceMidnight + maxTime > 23.5) {
                          maxTime = 24 - startInHoursSinceMidnight - 0.5;
                        }


                        if (value.localeCompare(getEndTime(startTime, 0)) !== 1) {
                          return false;
                        }

                        return value.localeCompare(getEndTime(startTime, maxTime)) !== 1;
                      }
                    }}
                  />
                </Col>
              </Row>
              <Divider />
              <Controller
                name="destination"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="mb-2 required">
                    <Form.Label>{t("bookingForm.labelDestination")}</Form.Label>
                    <Form.Control
                      type="text"
                      {...field}
                      isInvalid={!!errors.destination}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.destination?.message}
                    </Form.Control.Feedback>
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("common.messages.required", {
                      val: t("bookingForm.labelDestination"),
                    }),
                  },
                  maxLength: {
                    value: 500,
                    message: t("common.messages.length", { val: 500 })
                  },
                }}
              />
              <Controller
                name="fullNameOfResponsibleClient"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="mb-2 required">
                    <Form.Label>{t("bookingForm.labelName")}</Form.Label>
                    <Form.Control
                      type="text"
                      {...field}
                      isInvalid={!!errors.fullNameOfResponsibleClient}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.fullNameOfResponsibleClient?.message}
                    </Form.Control.Feedback>
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("common.messages.required", {
                      val: t("bookingForm.labelName"),
                    }),
                  },
                  maxLength: {
                    value: 500,
                    message: t("common.messages.length", { val: 500 })
                  },

                }}
              />
              <div className="d-flex justify-content-between">
                <h5>{t("bookingForm.labelAdditionalNames")}</h5>
                <Button
                  variant="secondary"
                  disabled={watch("additionalClients").length === (boats.find(x => x.id === watch("boatName"))?.boattype?.seats || 1) - 1}
                  type="button"
                  onClick={() => appendPerson({ passenger: "" })}
                >
                  +
                </Button>
              </div>

              <ul>
                {additionalClients.map((item: any, index: number) => (
                  <div key={item.id}>
                    <div className="my-2">
                      <Controller
                        name={`additionalClients.${index}.passenger` as const}
                        control={control}
                        render={({ field, fieldState }) => (
                          <div className="required">
                            <div className="mb-2 d-flex">
                              <Form.Control
                                type="text"
                                {...field}
                                isInvalid={!!fieldState.invalid}
                              />
                              <Button
                                className="mx-3"
                                variant="danger"
                                type="button"
                                onClick={() => removePerson(index)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className="text-white"
                                />
                              </Button>
                            </div>
                            {/* Doesn't work! Only appears when in the div above. TODO? */}
                            <Form.Control.Feedback type="invalid">
                              {errors.additionalClients && errors.additionalClients[index] && errors.additionalClients[index]?.passenger?.message}
                            </Form.Control.Feedback>
                          </div>
                        )}
                        rules={{
                          maxLength: {
                            value: 500,
                            message: t("common.messages.length", { val: 500 })
                          },
                        }}
                      />
                    </div>
                  </div>
                ))}
              </ul>
              <Divider />
              <div style={{ display: "flex" }}>
                <Controller
                  name="isCourse"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Form.Check
                      inline
                      type="checkbox"
                      id="isCourse"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                    />
                  )}
                />
                {t("bookingForm.isCourse")}
              </div>
              <Divider />
              <div style={{ display: "flex" }}>
                {/* Slightly different than other Controller/Control pairs. No validation is done, instead the value of the Checkbox directly stops the Submitbutton from working.
                Arguably hacky, but the way it normally is done here didn't work, for some reason. */}
                <Controller
                  name="termsAndConditions"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Form.Check
                      inline
                      type="checkbox"
                      id="termsAndConditions"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                    />
                  )
                  }
                />
                <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: agb[i18n.language] || "" }} />
              </div>
              <Divider />
              <Button type="submit" variant="secondary" className="mt-2 w-100" disabled={!watch("termsAndConditions")}>
                {t("bookingForm.buttonBookBoat")}
              </Button>
            </Form>
          </Modal>
        </Col>
      </Row>
    </Container >
  );
}

export default Book;

import { AccessoryType, ID } from "../types"
export async function getAccessoryTypesFromApi() {
  const response = await fetch("/api/accessorytype", {
    method: "GET",
  });
  const json = await response.json();
  if (json.result) {
    json.result = json.result.map((x: AccessoryType) => { return Object.assign(x, {}) })
  }
  return json;
}

export async function addAccessoryType({
  name,
  BoatTypes,
}: Omit<AccessoryType, "id">) {
  const response = await fetch("/api/accessorytype", {
    method: "POST",
    body: JSON.stringify({
      name,
      boattypes: BoatTypes,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function editAccessoryType({
  id,
  name,
  BoatTypes,
}: AccessoryType) {

  const response = await fetch(`/api/accessorytype/${id}`, {
    method: "PATCH",
    body: JSON.stringify({
      name,
      boattypes: BoatTypes,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export async function deleteAccessoryType({ id }: ID) {
  const response = await fetch(`/api/accessorytype/${id}`, {
    method: "DELETE",
  });
  const json = await response.json();
  return json;
}
